import {
    Box,
    Button,
    Center,
    css,
    Flex,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    VStack,
    ChakraProvider, extendTheme, Image
} from "@chakra-ui/react";
import {StepsStyleConfig as Steps} from 'chakra-ui-steps';
import React, {useState, useEffect} from "react";
import {For} from "react-haiku";
import {CAMPAIGN_BRIEFS_TABS, TABS_FOR_CLIENT, TABS_FOR_CLIENT_APPROVED} from "../../../constant";
import {useGetClientDetailsOnClick} from "../../../hooks/clients/useGetClientDetails";
import CreateForm from "./CampaignDetails/CreateForm";
import {BiPlusCircle, BiArrowBack} from "react-icons/bi";
import {TEXT_COLOR} from "../../../layout/constant/MenuList";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import CreateFacebookCampaign from "./FbCampaigns/CreateFacebookCampaignModel";
import CreateDV360Campaign from "./Dv360Campaings/CreateDV360CampaignModel";
import AdUploadList from "./AdUpload/AdUploadList";
import {Comment} from "./Comment";
import {NewComment} from "./NewComment";
import {profile} from "../../../atoms/authAtom";
import {useDispatch, useSelector} from "react-redux";
import FbCampaignList from "./FbCampaigns/FbCampaignList";
import DV360CampaignList from "./Dv360Campaings/Dv360CampaignList";
import FbAdSetsList from "./FbAdSets/FbAdSetsList";
import AuditLogsList from "./AuditLogsList";
import {useGetCampaign} from "../../../hooks/campaign-briefs/useGetCampaign";
import {useUpdateCampaign} from "../../../hooks/campaign-briefs/useUpdateCampaign";
import {updateCampaignFbObjective} from "../../../store/campaign/campaignThunk";
import {ROLES} from "../../../constant";
import CreateFormStepByStep from "./CampaignDetailsStepByStep/CreateFormStepByStep";
import "./CampaignDetailsStepByStep/FormStepButton";
import StepByStepAdUpload from "./AdUpload/StepByStepAdUpload";
import SelectAdType from "./AdUpload/SelectAdType";
import {ContextWrapper} from "../ContextStore/ContextWrapper";
import NewCreateStepByStepForm from "./CampaignDetailsStepByStep/NewCreateStepByStepForm";
import {deleteAllStateFromRedux} from "./AdUpload/deleteAllStateFromRedux";
import VectorIcon from "../../../assets/images/Vector.png";
import CreateAdCampaign from "../../AdCampaigns/CreateAdCampaign";

const theme = extendTheme({
    components: {
        Steps,
    },
});

function DynamicHeading(value) {
    return <h1>Dynamic {value}</h1>
}

const CreateCampaign = (effect, deps) => {
    const [tabIndex, SetTabIndex] = useState(0);
    const [isRemoveTabs, SetIsRemoveTabs] = useState(null);
    const dispatch = useDispatch();
    const newLocation = useLocation();
    const {
        isOpen: isFacebookModelOpen,
        onOpen: onFacebookModelOpen,
        onClose: onFacebookModelClose,
    } = useDisclosure();
    const {
        isOpen: isDV360odelOpen,
        onOpen: onDV360ModelOpen,
        onClose: onDV360ModelClose,
    } = useDisclosure();

    const clientId = useSelector((state) => state.client.clientId);

    const navigate = useNavigate();
    const location = useLocation();
    const [isNew, setIsNew] = useState(false)
    const [isClient, setIsClient] = useState(false)
    const {id} = useParams();
    const {mutate, data} = useGetClientDetailsOnClick();
    const {mutate: updateCampaignStatus} = useUpdateCampaign();
    const {data: campaignData, mutate: mutateGetCampaign} = useGetCampaign();
    const {
        access_info: {roles},
    } = useRecoilValue(profile);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        if (roles) {
            let isMatch = roles.find((item) => item.includes(ROLES.CLIENT));
            if (isMatch) {
                SetIsRemoveTabs(false)
            } else {
                SetIsRemoveTabs(true)
            }
        }
    }, [roles]);

    useEffect(() => {
        if (clientId) {
            mutate({id: clientId});
            mutateGetCampaign({
                clientId,
                campaignId: id,
            });
        }
    }, [clientId, id]);

    useEffect(() => {
        if (campaignData?.campaign?.detail?.objective) {
            dispatch(
                updateCampaignFbObjective(campaignData.campaign.detail.objective)
            );
        }
    }, [campaignData]);

    useEffect(() => {
        if(!roles.includes("Client")){
            if (location.state && location.state.isShowUpload) {
                setTimeout(() => {
                    SetTabIndex(1);
                }, 1000);
            }
        }
    }, []);

    const OnUpdateValue = () => {
        updateCampaignStatus({
            clientId,
            campaignBriefId: id,
            status: "Approved",
        });
        navigate("/campaign-briefs");
    };

    useEffect(() => {
        localStorage.removeItem("pageIndex")
    }, [])


    useEffect(() => {
        let newCampaign = window.location.pathname.split("/").pop()

        if (newCampaign === "new") {
            setIsNew(true)
        } else {
            setIsNew(false)
        }
    })

    function navigateToCampaign() {
        navigate("/campaign-briefs")
        deleteAllStateFromRedux(dispatch)
    }


    const [campaignName, setCampaignName] = useState("Campaign Name")


    return (
        <>
            <Flex templateColumns="repeat(6, 1fr)" alignItems={"flex-start"} gap={4}>
                <Box
                    className={isClient ? (tabIndex === 0 ? "create-campaign-box" : "create-campaign-main") : tabIndex === 1 ? "create-campaign-box" : "create-campaign-main"}
                >
                    <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                        <Flex onClick={navigateToCampaign} cursor={"pointer"}>
                            {/*<Center marginRight={2}>*/}
                            {/*    <BiArrowBack color="#A7A9BD"/>*/}
                            {/*</Center>*/}
                            <Center>
                                <Button
                                    leftIcon={<Image src={VectorIcon}/>}
                                    color="#79A9FF"
                                    fontWeight={500}
                                    border="2px"
                                    borderRadius={0}
                                    pr={6}
                                    fontFamily={"poppins"}
                                    bgColor={"white"}
                                    variant={"outline"}
                                >
                                    Back
                                </Button>
                            </Center>
                        </Flex>
                    </Heading>
                    <Heading fontSize="30px" mb={4} color={"#757998"} fontFamily={"Lora"} fontWeight={600}>
                        {
                            tabIndex === 4 && <span>{campaignData?.campaign?.name} Ad Set <span> &#8226;</span><span
                                className={"Draft"}> Draft</span></span>
                        }
                        {
                            tabIndex !== 4 && id && campaignData?.campaign?.status === 'Approved' &&
                            <span>{campaignData?.campaign?.name}<span> &#8226;</span><span
                                className={"Approve"}> Approved</span></span>
                        }
                        {
                            tabIndex !== 4 && id && campaignData?.campaign?.status === 'Rejected' &&
                            <span>{campaignData?.campaign?.name}<span> &#8226;</span><span
                                className={"Reject"}> Rejected</span></span>
                        }
                        {
                            tabIndex !== 4 && id && campaignData?.campaign?.status === 'Created' &&
                            <span>{campaignData?.campaign?.name}<span> &#8226;</span><span className={"Approve"}> Awaiting Approval</span></span>
                        }
                        {
                            isNew &&
                            <span> {campaignName} <span> &#8226;</span><span className="Draft"> Draft</span></span>
                        }
                    </Heading>
                    <Tabs
                        index={(isClient && campaignData?.campaign?.status !== "Approved") ? tabIndex + 1 : tabIndex }
                        size="sm"
                        w="full"
                        color="#A7A9BD"
                        fontFamily={"poppins"}
                        fontWeight={600}
                        overflow="hidden"
                        isLazy
                        onChange={(index) => SetTabIndex(index)}
                    >
                        <TabList overflowX="auto" css={css({
                            scrollbarWidth: "none",
                            "::-webkit-scrollbar": {display: "none"},
                            WebkitOverflowScrolling: "touch",
                            boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                            border: "0 none",
                        })}
                        >
                            <For
                                each={CAMPAIGN_BRIEFS_TABS}
                                render={(tab, index) => {
                                    const isShowTab = (tab.hideTabForClient && !isRemoveTabs) ? false : true
                                    if (isShowTab) {
                                        return (
                                            <Tab
                                                key={index}
                                                whiteSpace="nowrap"
                                                marginBottom="0"
                                                // className={`${(!isClient && campaignData?.campaign?.status !== "Approved" && tab.title === "AD uploads") ? 'disable' : ''}`}
                                                isDisabled={
                                                    !id || (isClient && campaignData?.campaign?.status !== "Approved") &&
                                                    (tab.title === "AD uploads" || tab.title === "Comments")
                                                }
                                            >
                                                {tab.title}
                                            </Tab>
                                        );
                                    }
                                }}
                            />
                        </TabList>
                        <TabPanels>
                            {!isClient &&
                            <TabPanel>
                                <Flex justifyContent="space-between">
                                    <Heading color={TEXT_COLOR} size="md" opacity={0}>
                                        Current Status:{" "}
                                        <span>{campaignData?.campaign?.status || "Draft"}</span>
                                    </Heading>
                                    {id && campaignData?.campaign?.status === "Created" && (
                                        <Box display="flex">
                                            <Button
                                                size="sm"
                                                mr={2}
                                                colorScheme="green"
                                                backgroundColor="green.400"
                                                borderRadius={4}
                                                disabled={!clientId}
                                                onClick={() => OnUpdateValue()}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                size="sm"
                                                colorScheme="red"
                                                backgroundColor="red.400"
                                                borderRadius={4}
                                                disabled={!clientId}
                                                onClick={() => {
                                                    updateCampaignStatus({
                                                        clientId,
                                                        campaignBriefId: id,
                                                        status: "Rejected",
                                                    })
                                                    navigate("/campaign-briefs");
                                                }
                                                }
                                            >
                                                Reject
                                            </Button>
                                        </Box>
                                    )}
                                </Flex>
                                <ChakraProvider theme={theme}>
                                    {/*<CreateFormStepByStep*/}
                                    {/*    id={id}*/}
                                    {/*    clientDetails={data?.client || JSON.parse(localStorage.getItem("client"))}*/}
                                    {/*    campaignDetails={campaignData?.campaign}*/}
                                    {/*/>*/}
                                    <NewCreateStepByStepForm setCampaignName={setCampaignName} id={id}
                                                             clientDetails={data?.client || JSON.parse(localStorage.getItem("client"))}
                                                             campaignDetails={campaignData?.campaign}/>
                                    {/*<CreateForm*/}
                                    {/*        id={id}*/}
                                    {/*        clientDetails={data?.client || JSON.parse(localStorage.getItem("client"))}*/}
                                    {/*        campaignDetails={campaignData?.campaign}*/}
                                    {/*/>*/}
                                </ChakraProvider>
                            </TabPanel>
                            }
                            <TabPanel>
                                {/*<AdUploadList/>*/}
                                <ContextWrapper>
                                    <StepByStepAdUpload SetTabIndex={SetTabIndex}/>
                                </ContextWrapper>
                            </TabPanel>
                            <TabPanel>
                                <AuditLogsList/>
                            </TabPanel>
                            <TabPanel>
                                <ContextWrapper>
                                    <StepByStepAdUpload SetTabIndex={SetTabIndex}/>
                                </ContextWrapper>
                            </TabPanel>
                            <TabPanel>
                                <ContextWrapper>
                                    <ChakraProvider theme={theme}>
                                        <SelectAdType SetTabIndex={SetTabIndex}/>
                                    </ChakraProvider>
                                </ContextWrapper>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
                <Box
                    position="fixed"
                    right="0"
                    width="22%"
                    height={"auto"}
                    padding={5}
                    colSpan={{base: 6, lg: tabIndex === 0 ? 2 : 0}}
                    display={{base: "none", lg: "block"}}
                >
                    {id && (isClient ? tabIndex === 1 : tabIndex === 0) && (
                        // <Comment clientId={clientId} campaignId={id}/>
                        <NewComment clientId={clientId} campaignId={id}/>
                    )}
                </Box>

                <CreateDV360Campaign
                    isOpen={isDV360odelOpen}
                    onClose={onDV360ModelClose}
                    clientId={clientId}
                    data={campaignData?.campaign}
                />

                <CreateFacebookCampaign
                    isOpen={isFacebookModelOpen}
                    onClose={onFacebookModelClose}
                    clientId={clientId}
                    data={campaignData?.campaign}
                />
            </Flex>
        </>

    );
};

export default CreateCampaign;
