import React, {useEffect, useState} from 'react';
import {Flex, Heading} from "@chakra-ui/layout";
import {
    Box,
    Button, Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image, Menu, MenuButton, MenuItem, MenuList,
    Select,
    Text,
    useToast
} from "@chakra-ui/react";
import {Input} from "@chakra-ui/input";
import {Form, Formik} from "formik";
import MediaStrategyDialog from "../../layout/components/MediaStrategyDialog";
import {CampaignInventoryRestriction, countryLanguages} from "../../constant";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom'
import {deleteAllAdCampaignFields, settingMediaStrategies} from "../../store/adCampaign";
import ConcentAgreementDialog from "../../layout/components/ConsentAgreementDialog";
import axios from "../../helpers/axios";
import ErrorModal from "../../components/PopupModal/ErrorModal";
import './adCampaign.css'
import * as Yup from "yup";
import _ from "lodash"
import {ChevronDownIcon, CloseIcon} from "@chakra-ui/icons";
import SuccessModal from "../../components/PopupModal/SuccessModal";
import {DeviceOptions} from "../CampaignBriefs/constant/SelectValues";


const initialValue = {
    device: "",
    language: "",
    competitors: ""
}
const validationSchema = Yup.object({
    device: Yup.string().required().label("Device"),
    language: Yup.string().required().label("Language"),
});

function MediaStrategies({prevStep, nextStep, handleNotes}) {
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState("");
    const [isSuccessModelOpen, setIsSuccessModelOpen] = useState(false)
    const detail = useSelector((state) => state.adCampaign)
    const clientId = useSelector((state) => state.client.clientId);
    const [open, setOpen] = useState(false)
    const [openAgreement, setOpenAgreement] = useState(false)
    const [mediaFormDetails, setMediaFormDetails] = useState(initialValue);
    const [selectedSafetyOptions, setSelectedSafetyOptions] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const {id} = useParams()

    useEffect(() => {
        if (!_.isEmpty(detail.mediaStrategies)) {
            setMediaFormDetails(detail.mediaStrategies)
            if (detail.mediaStrategies.brandSafety) {
                setSelectedSafetyOptions(detail.mediaStrategies.brandSafety)
            }
        }
    }, [detail.mediaStrategies])

    function handleOpenDialog() {
        setOpen(true)
    }

    function handleCloseDialog() {
        setOpen(false)
    }

    function handleOpenAgreement() {
        setOpenAgreement(true)
    }

    function handleCloseAgreement() {
        setOpenAgreement(false)
    }

    function handleSubmit() {
        if (id) {
            const payload = {
                name: detail.campaignDetails.campaignName,
                startDate: detail.campaignBudget.startDate,
                endDate: detail.campaignBudget.endDate,
                business_id: detail.businessDetails.id,
                detail
            }
            axios.put(`/client/${clientId}/campaign-brief/${id}`, payload)
                .then((res) => {
                        setIsSuccessModelOpen(true)
                        toast({
                            isClosable: true,
                            status: "success",
                            variant: "top-accent",
                            position: "top-right",
                            description: "Campaign Updated Successfully",
                        })
                        dispatch(deleteAllAdCampaignFields())
                    }
                ).catch(err => {
                setErrorModal(true)
                setErrorSubmit(err.response.data.message);
            })
        } else {
            handleOpenAgreement()
        }
    }

    function handleChange(e) {
        const {name, value} = e.target
        setMediaFormDetails({...mediaFormDetails, [name]: value})
        dispatch(settingMediaStrategies({...mediaFormDetails, [name]: value}))
    }

    function handleChecked(e) {
        const {checked, value} = e.target;

        if (checked) {
            const newSelectedSafetyOptions = [...selectedSafetyOptions, value];

            dispatch(settingMediaStrategies({
                ...detail.mediaStrategies,
                brandSafety: newSelectedSafetyOptions,
            }));
        } else {
            const filteredOptions = selectedSafetyOptions.filter((e) => e !== value);

            dispatch(settingMediaStrategies({
                ...detail.mediaStrategies,
                brandSafety: filteredOptions,
            }));
        }
    }

    function handleRemoveOptions(e){
        e.stopPropagation()
        setSelectedSafetyOptions([])
    }

    function onCloseSuccessModal(){
        setIsSuccessModelOpen(false)
        navigate("/campaign-briefs")
    }

    return (
        <>
            <div style={{fontFamily: "poppins"}}>
                <Flex justifyContent='space-between' alignItems='center'>
                    <Heading mt={5} fontSize="3xl" fontFamily='poppins'>Media Strategies</Heading>
                    <Button variant='none' color='#1F95FF' p={0} onClick={() => handleOpenDialog()}>Advanced
                        Settings</Button>
                </Flex>
                <Formik initialValues={mediaFormDetails}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize>
                    {({values, errors, setFieldValue, touched}) => (
                        <Box as={Form} noValidate>
                            <Box minHeight={460} fontFamily={"poppins"}>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} mt={10}>
                                    <GridItem>
                                        <FormControl isRequired>
                                            <Flex gap={1}>
                                                <Image
                                                    mt={1}
                                                    src={`${process.env.PUBLIC_URL}/images/devices.svg`}
                                                    height="18px"
                                                />
                                                <FormLabel>Devices</FormLabel>
                                            </Flex>
                                            <Select
                                                id="device"
                                                name="device"
                                                placeholder="Select Device"
                                                variant="outline"
                                                border="2px"
                                                borderRadius={1}
                                                borderColor="#E7E7E9"
                                                fontWeight={500}
                                                value={values.device}
                                                isInvalid={errors.device && touched.device}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("device", e.target.value)
                                                }}
                                            >
                                                {DeviceOptions.map((objective) => (
                                                    <option className={'campaign-option'} key={objective.value}
                                                            value={objective.value}>
                                                        {objective.value}
                                                    </option>
                                                ))}
                                            </Select>
                                            {errors.device && touched.device && (
                                                <Text color="red.500" fontSize="sm" mt={2}>
                                                    {errors.device}
                                                </Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isRequired>
                                            <FormLabel color='#363849'>Language</FormLabel>
                                            <Select
                                                id="detail.objective"
                                                name="language"
                                                placeholder="Select Language"
                                                variant="outline"
                                                border="2px"
                                                fontWeight={500}
                                                borderRadius={1}
                                                borderColor="#E7E7E9"
                                                value={values.language}
                                                isInvalid={errors.language && touched.language}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("language", e.target.value)
                                                }}
                                            >
                                                {countryLanguages.map((objective) => (
                                                    <option className={'campaign-option'} key={objective}
                                                            value={objective}>
                                                        {objective}
                                                    </option>
                                                ))}
                                            </Select>
                                            {errors.language && touched.language && (
                                                <Text color="red.500" fontSize="sm" mt={2}>
                                                    {errors.language}
                                                </Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl>
                                            <FormLabel color='#363849'>Top Competitors</FormLabel>
                                            <Input placeholder='Top Competitors (Comma Seperated)' name='competitors'
                                                   value={values.competitors}
                                                   onChange={(e) => {
                                                       handleChange(e);
                                                       setFieldValue("competitors", e.target.value)
                                                   }}/>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>

                                    </GridItem>
                                    <GridItem>
                                        <FormLabel color='#363849'>Brand Safety</FormLabel>
                                        <Menu closeOnSelect={false}>
                                            <MenuButton borderRadius='md'
                                                        borderWidth='2px'
                                                        textAlign={'left'}
                                                        bg={''}
                                                        _hover={{bg: ''}}
                                                        _expanded={{bg: ''}}
                                                        _focus={{boxShadow: 'outline'}} width={'full'} fontWeight={500}
                                                        as={Button} rightIcon={<Flex alignItems={'center'} gap={2}>
                                                <CloseIcon fontSize={12} onClick={handleRemoveOptions}/>
                                                <ChevronDownIcon fontSize={22}/>
                                            </Flex>}>
                                                {!!selectedSafetyOptions.length ? selectedSafetyOptions.join(", ") : "Select Safety Option"}
                                            </MenuButton>
                                            <MenuList minWidth={616} maxHeight={260} overflow={"auto"}>
                                                {CampaignInventoryRestriction.map((item) => (
                                                    <MenuItem fontWeight={500}><Checkbox
                                                        isChecked={selectedSafetyOptions.includes(item.value)}
                                                        value={item.value}
                                                        onChange={(e) => handleChecked(e)}>{item.label}</Checkbox></MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Divider my={8}/>
                            <div style={{display: 'flex', justifyContent: "space-between"}}>
                                <Button fontWeight={400} fontFamily={"poppins"} leftIcon={<Image
                                    src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                                    height="18px"
                                />} onClick={handleNotes}>Add Notes</Button>
                                <div style={{display: "flex", justifyContent: "flex-end", fontFamily: "poppins"}}>
                                    <Button mr={4} _hover={{bg: ''}} color={"#1F95FF"} bgColor={"#FFFFFF"}
                                            border={"2px"} borderColor="#1F95FF" py={5} px={8}
                                            onClick={() => prevStep()}>Back</Button>
                                    <Button type='submit' _hover={{bg: ''}} color={"#FFFFFF"} bgColor={"#1F95FF"}
                                            border={"2px"} borderColor="#1F95FF" py={5} px={8}>Next</Button>
                                </div>
                            </div>
                        </Box>)}
                </Formik>

                <MediaStrategyDialog onClose={handleCloseDialog} isOpen={open}/>
                <ConcentAgreementDialog onClose={handleCloseAgreement} isOpen={openAgreement}/>

            </div>
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={errorSubmit}
            />
            <SuccessModal
                isOpen={isSuccessModelOpen}
                onClose={() => onCloseSuccessModal()}
            />
        </>
    );
}

export default MediaStrategies;