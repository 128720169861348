import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Text, GridItem, FormControl, FormLabel, Select, Grid, Button, Box,
} from '@chakra-ui/react'
import React, {useEffect, useState} from "react";
import {SubmitButton} from "formik-chakra-ui";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import InputBox from "../../components/InputBox";
import '../../pages/AdCampaigns/adCampaign.css'
import {useGetCampaignIndustries} from "../../hooks/config-management/useGetCampaignIndustries";

const validationSchema = Yup.object({
    name: Yup.string().required().label("Business Name"),
    contactName: Yup.string().required().label("Contact Name"),
    industry: Yup.string().required().label("Industry"),
    address: Yup.string().required().label("Address"),
    phone: Yup.number().required().label("Phone Number"),
    email: Yup.string().email().required().label("Email Address"),
});


function CampaignModal({onClose, isOpen, dialogTitle, buttonType, onSubmit, reportData}) {
    const [initialFormValue, setInitialFormValue] = useState(reportData)

    useEffect(() => {
        setInitialFormValue(reportData)
    }, [reportData]);

    const [industries, setIndustries] = useState([])
    const {data} = useGetCampaignIndustries()

    useEffect(() => {
        setIndustries(data?.data)
    }, [data])

    return (
        <>
            <Modal onClose={onClose} isOpen={isOpen} isCentered size='5xl'>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{dialogTitle}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Formik initialValues={initialFormValue}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                enableReinitialize>
                            {({values, errors,setFieldValue,touched}) => (
                                <Box as={Form} noValidate style={{color: '#1D1D1D'}}>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>

                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel fontSize={'md'}>Business Name</FormLabel>
                                                <InputBox name="name" borderColor="#A7A9BD"
                                                          borderRadius={1}
                                                          fontWeight={600}/>
                                            </FormControl>
                                        </GridItem><GridItem>
                                        <FormControl isRequired>
                                            <FormLabel fontSize={'md'}>Contact Name</FormLabel>
                                            <InputBox name="contactName" borderColor="#A7A9BD"
                                                      fontSize={14}
                                                      borderRadius={1} color={"#757998"}
                                                      fontWeight={600}/>
                                        </FormControl>
                                    </GridItem><GridItem>
                                        <FormControl isRequired>
                                            <FormLabel fontSize={'md'}>Phone Number</FormLabel>
                                            <InputBox name="phone" type='number' borderColor="#A7A9BD"
                                                      fontSize={14}
                                                      borderRadius={1} color={"#757998"}
                                                      fontWeight={600}/>
                                        </FormControl>
                                    </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel fontSize={'md'}>Email Address</FormLabel>
                                                <InputBox name="email"  borderColor="#A7A9BD"
                                                          fontSize={14}
                                                          borderRadius={1} color={"#757998"}
                                                          fontWeight={600}/>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired >
                                                <FormLabel fontSize={'md'}>Industry</FormLabel>
                                                <Select
                                                    id="industry"
                                                    fontSize={'md'}
                                                    name="industry"
                                                    fontWeight={600}
                                                    placeholder="Select Industry"
                                                    variant="outline"
                                                    border="2px"
                                                    borderRadius={1}
                                                    borderColor="#E7E7E9"
                                                    value={values.industry}
                                                    onChange={(e) => setFieldValue("industry", e.target.value)}
                                                    isInvalid={errors.industry && touched.industry}
                                                >
                                                    {!!industries && industries.map((objective) => (
                                                        <option className={'campaign-option'} key={objective.id} value={objective.industry}>
                                                            {objective.industry}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.industry && touched.industry && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.industry}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem colSpan={2}>
                                            <FormControl isRequired>
                                                <FormLabel fontSize={'md'}>Address</FormLabel>
                                                <InputBox name="address"  borderColor="#A7A9BD"
                                                          fontSize={14}
                                                          borderRadius={1} color={"#757998"}
                                                          fontWeight={600}/>
                                            </FormControl>
                                        </GridItem>
                                    </Grid>

                                    <SubmitButton
                                        display={'flex'} justifyContent={'flex-end'}
                                        py={5} fontFamily={"poppins"} px={8} _hover={{ bg: '' }} color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"} borderColor="#1F95FF"
                                        style={{margin: "1.4rem 0 1.4rem auto"}}
                                    >
                                        {buttonType}
                                    </SubmitButton>
                                </Box>
                            )
                            }
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CampaignModal