import { CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button, CircularProgress,
    CircularProgressLabel, css, FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, Icon, Image, useDisclosure, Flex
} from "@chakra-ui/react";
import {Form, FormikProvider, useFormik} from "formik";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import instance from "../../../../../../helpers/axios";
import {useUpdateAdUploadStatus} from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {
    pinterestAdUploadvalueLengths,
    pinterestCarouselInitialValues, pinterestCarouselFileInitialValues
} from "../../../../constant/InitialValues";
import {facebookAccountIds} from "../../../../constant/SelectValues";
import Main from "./UploadFiles/main";
import DefaultImageIcon from "../../../../../../assets/images/image-default-icon.png";
import DefaultVideoIcon from "../../../../../../assets/images/video-default-icon.png";
import {AD_UPLOAD_STATUS_COLOR, AD_UPLOAD_TYPE} from "../../../../../../constant";
import _ from "lodash";
import {useCreateAdPreview} from "../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import WithAppContext from "../../../../../../helpers/appContext";
import FacebookAdSetModel from "../../Facebook/FacebookAdSetModel";

const instagramCarouselSchema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    link: yup.string().trim().required("Landing page url is required.").label("Landing page url")
        .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            "Landing page url is not valid!"
        ),
    carouselCards: yup.array().min(2).max(5).required().label("Carousel cards"),
});

const InstagramCarouselAdDetails = ({
                                        data,
                                        url,
                                        method,
                                        clientId,
                                        campaignId,
                                        handlePreviewData,
                                        setTabIndex,
                                        tabIndex,
                                        context,
                                        details
                                    }) => {
    const [formData, setFromData] = useState(pinterestCarouselInitialValues);
    const [hashArray, setHashArray] = useState([]);
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const [isShowCardModal, setIsShowCardModal] = useState(false);
    const [cardModalType, seCardModalType] = useState('image');
    const [initialModalData, setInitialModalData] = useState(pinterestCarouselFileInitialValues);
    const [activeCarouselCardId, setActiveCarouselCardId] = useState(null);
    const {setAppLoading, ErrorToaster} = context

    const [setImages, setSetImages] = useState([])


    const {
        isOpen: isFacebookModelOpen,
        onOpen: onFacebookModelOpen,
        onClose: onFacebookModelClose,
    } = useDisclosure();

    const {mutate} = useUpdateAdUploadStatus();
    const {mutateAsync} = useCreateAdPreview();

    const formik = useFormik({
        initialValues: formData,
        validationSchema: instagramCarouselSchema,
        onSubmit: async (values) => {
            setAppLoading(true);
            setFromData(values)
            let payload = {
                name: values.adName,
                description: values.description,
                detail: {
                    name: values.adName,
                    description: values.description,
                    link: values.link,
                    child_attachments: values.carouselCards,
                },
            };
            if (!data?.id) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.PINTEREST_CAROUSEL,
                };
            }
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setAppLoading(false);
                        setSuccessModal(true);
                        navigate(`/campaign-briefs/${campaignId}`, {
                            state: {isShowUpload: true}
                        })
                    }
                })
                .catch((error) => {
                    setAppLoading(false);
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                });
            setAppLoading(false);
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
    } = formik;


    useEffect(() => {
        if (tabIndex === 1) {
            // console.log('values', values)
            if (values && values.carouselCards.length > 0) {
                const files = values.carouselCards.map((file) => {
                    if (file && file.imageURL.filename) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.imageURL.filename}`,
                            filename: file.imageURL.originalname
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file.imageFile),
                            filename: file.imageURL.name
                        }
                    }
                })
                handlePreviewData({
                    images: files,
                    name: values.adName,
                    description: values.description,
                });
            }
        }
    }, [tabIndex]);

    useEffect(() => {
        if (data && data.id) {
            const { name, detail ,description} = data;
            setFieldValue('adName', name);
            setFieldValue('description', description);
            setFieldValue('link', detail.link);
            setFieldValue('carouselCards', detail.child_attachments);
        }
    }, [data])
    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        navigate(`/campaign-briefs/${campaignId}`, {
            state: {isShowUpload: true}
        })
    }

    const createCarouselAd = (data) => {
        let CarouselCardsData = [...values.carouselCards]
        if (activeCarouselCardId !== null) {
            CarouselCardsData[activeCarouselCardId] = data
        } else {
            CarouselCardsData.push(data)
        }
        setFieldValue('carouselCards', CarouselCardsData)
        setActiveCarouselCardId(null)
    }

    const onDelete = (id) => {
        let CarouselCardsData = [...values.carouselCards]
        CarouselCardsData.splice(id, 1)
        setFieldValue('carouselCards', CarouselCardsData)
    }

    const adUploadStatusColor = (item) => {
        return item ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)] : AD_UPLOAD_STATUS_COLOR["OTHER"];
    }

    const handleStatus = (status) => {
        setAppLoading(true);
        mutate(
            {
                clientId,
                campaignId,
                adUploadId: data?.id,
                status,
            },
            {
                onSuccess: () => {
                    setAppLoading(false);
                    navigate(`/campaign-briefs/${campaignId}`, {
                        state: {isShowUpload: true},
                    });
                },
                onError: () => {
                    setAppLoading(false);
                }
            }
        )
        setAppLoading(false);
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" my={4} mb={7}>
                <Heading color={"gray"} fontSize="xl">
                    Current status:
                    <span style={{marginLeft: "10px"}}>
                        <Icon
                            viewBox="0 0 200 200"
                            mr={2}
                            color={adUploadStatusColor(data && data.status)}
                        >
                            <path
                                fill="currentColor"
                                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                            />
                        </Icon>
                        {data && data.status || "Draft"}
                    </span>
                </Heading>
                {data && _.toUpper(data.status) === "CREATED" &&
                    <Box display="flex">
                        <Button
                            size="sm"
                            mr={2}
                            colorScheme="green"
                            backgroundColor="green.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Approved')}
                        >
                            Approve
                        </Button>
                        <Button
                            size="sm"
                            colorScheme="red"
                            backgroundColor="red.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Rejected')}
                        >
                            Reject
                        </Button>
                    </Box>
                }
            </Box>
            <Grid className="fb-upload-detail-form">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid
                            gap={4}
                            className="fb-upload"
                            templateColumns='repeat(2, 1fr)'
                        >
                            <GridItem w='100%'>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="adName"
                                        color="gray"
                                        fontSize="sm"
                                    >
                                        Ad Name - max 100 characters ( Your first 40 characters are what usually show up
                                        in feeds. )
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="adName"
                                            name="adName"
                                            value={values.adName}
                                            placeholder=""
                                            inputprops={{
                                                variant: "outline",
                                                type: "text",
                                            }}
                                            onChange={(e) => setFieldValue('adName', e.target.value)}
                                        />
                                        <CircularProgress
                                            max={pinterestAdUploadvalueLengths?.adName}
                                            value={values?.adName?.length}
                                            color={values?.adName?.length > pinterestAdUploadvalueLengths?.adName ? "red.400" : "green.400"}
                                        >
                                            <CircularProgressLabel>
                                                {values?.adName?.length > pinterestAdUploadvalueLengths?.adName ? pinterestAdUploadvalueLengths?.adName - values?.adName?.length : values?.adName?.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Grid
                            gap={4}
                            className="fb-upload"
                            templateColumns='repeat(2, 1fr)'
                        >
                            <GridItem>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="description"
                                        color="gray"
                                        fontSize="sm"
                                    >
                                        Description - max 500 characters ( People will usually see the first 50
                                        characters when they click on your Pin. )
                                    </FormLabel>
                                    <div className="input-box">
                                        <TextareaControl
                                            id="description"
                                            name="description"
                                            value={
                                                values.description
                                            }
                                            placeholder=""
                                            inputprops={{
                                                variant: "outline",
                                                type: "text",
                                            }}
                                            onChange={(e) => setFieldValue('description', e.target.value)}
                                        />
                                        <CircularProgress
                                            max={pinterestAdUploadvalueLengths.description}
                                            value={values.description.length}
                                            color={
                                                values.description.length > pinterestAdUploadvalueLengths.description ? "red.400" : "green.400"}
                                        >
                                            <CircularProgressLabel>
                                                {values.description.length > pinterestAdUploadvalueLengths.description ? pinterestAdUploadvalueLengths.description - values.description.length : values.description.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <GridItem w='100%' justifyContent="end" display="flex" alignItems="center"
                                  marginBottom={errors.carouselCards ? '8px' : "20px"}>
                            <Button variant='outline' onClick={() => {
                                seCardModalType('image')
                                setIsShowCardModal(true)
                            }}>Add Image Card</Button>
                        </GridItem>
                        <GridItem w='100%'
                                  display="flex"
                                  justifyContent="flex-end"
                                  marginBottom="20px"
                                  color="#e53e3e"
                                  fontSize="14px"
                                  css={css({
                                      float: "right",
                                  })}
                        >
                            {errors.carouselCards && errors.carouselCards}
                        </GridItem>
                        <Grid
                            w='100%'
                            gap={4}
                            marginBottom="20px"
                            className="fb-upload"
                            // templateColumns='repeat(8, 1fr)'
                            display="flex"
                            flexWrap="wrap"
                        >
                            {values.carouselCards && values.carouselCards.map((item, index) => {
                                let fileType = '';
                                let fileData = {};
                                fileType = item.fileType;

                                fileData = {
                                    name: item.imageFile.filename,
                                    imageURL: item.imageURL,
                                    imageFile: item.imageFile,
                                }

                                return (
                                    <Box key={index}
                                        //  backgroundColor="rgba(0, 0, 0, 0.39)"
                                        borderWidth='1px'
                                        width="110px" borderRadius="10px" display="flex" justifyContent="space-evenly"
                                        overflow="hidden">
                                        <Flex
                                            alignItems='center'
                                            onClick={() => {
                                                setInitialModalData(fileData)
                                                seCardModalType(fileType)
                                                setIsShowCardModal(true)
                                                setActiveCarouselCardId(index)
                                            }}>
                                            {fileType === 'image' &&
                                                <Image
                                                    height="80px"
                                                    width="80px"
                                                    objectFit="contain"
                                                    src={DefaultImageIcon}
                                                    alt="Default Image Icon"
                                                    cursor="pointer"
                                                />
                                            }
                                            {fileType === 'video' &&
                                                <Image
                                                    height="80px"
                                                    width="80px"
                                                    objectFit="contain"
                                                    src={DefaultVideoIcon}
                                                    alt="Default Video Icon"
                                                    cursor="pointer"
                                                />
                                            }
                                        </Flex>
                                        <CloseIcon
                                            marginTop="4px"
                                            padding="2px"
                                            bg="rgba(255, 255, 255, 0.45)"
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => {
                                                onDelete(
                                                    index
                                                )
                                            }
                                            }
                                        />
                                    </Box>
                                )
                            })}
                        </Grid>

                        <Grid
                            gap={4}
                            className="fb-upload"
                            templateColumns='repeat(2, 1fr)'
                        >
                            <GridItem w='100%'>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="link"
                                        color="gray"
                                        fontSize="sm"
                                    >
                                        Landing page URL
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="link"
                                            name="link"
                                            value={values.link}
                                            placeholder=""
                                            inputprops={{
                                                variant: "outline",
                                                type: "text",
                                            }}
                                            onChange={(e) => setFieldValue('link', e.target.value)}/>
                                        <Box h="48px" w="48px"/>
                                    </div>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <GridItem w='100%'
                                  display="flex"
                                  justifyContent="flex-end"
                                  paddingRight="65"
                                  css={css({
                                      float: "right",
                                  })}
                        >
                            <Button
                                size="small"
                                css={css({
                                    background: "#24a0ed !important",
                                    borderRadius: "32px",
                                    width: "134px",
                                    height: "33px",
                                    marginRight: "10px",
                                })}
                                disabled={values.carouselCards.length < 2 && values.type}
                                onClick={() => setTabIndex(1)}
                            >
                                Preview
                            </Button>
                            <Button
                                size="small"
                                css={css({
                                    background:
                                        "#4CAF50 !important",
                                    borderRadius: "32px",
                                    width: "134px",
                                    height: "33px",
                                })}
                                type="submit"
                                disabled={values.carouselCards.length < 2 && values.type}
                            >
                                {data?.id
                                    ? "Update Ad"
                                    : "Submit Ad"}
                            </Button>
                        </GridItem>
                        <Main
                            setHashArray={setHashArray}
                            hashArray={hashArray}
                            type="facebookCarousel"
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            cardModalType={cardModalType}
                            isShowCardModal={isShowCardModal}
                            setIsShowCardModal={setIsShowCardModal}
                            createCarouselAd={createCarouselAd}
                            initialModalData={initialModalData}
                            setInitialModalData={setInitialModalData}
                            activeCarouselCardId={activeCarouselCardId}
                            setActiveCarouselCardId={setActiveCarouselCardId}
                            setSetImages={setSetImages}
                            setImages={setImages}
                        />
                    </Form>
                </FormikProvider>
            </Grid>

            <FacebookAdSetModel
                isOpen={isFacebookModelOpen}
                onClose={onFacebookModelClose}
            />

            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
};

export default WithAppContext(InstagramCarouselAdDetails)
