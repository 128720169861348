import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    Text,
    Tooltip,
    IconButton,
    Box,
    Button
} from "@chakra-ui/react";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { useTable, usePagination, useSortBy } from "react-table";
import { MdFileDownload } from "react-icons/md";



const Datatable = ({ data, columns,headerColor,auditTrue,paginationDataCount}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: paginationDataCount || 5 },
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
        },
        useSortBy,
        usePagination
    );


    return (
        <>
            <TableContainer>
                <Table {...getTableProps()} variant="simple" size="sm">
                    <Thead backgroundColor={headerColor} height="44px">
                        {headerGroups.map((headerGroup) => (
                            <Tr{...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()

                                        )}
                                        onClick={() =>
                                            column.toggleSortBy(
                                                !column.isSortedDesc
                                            )
                                        }
                                    >
                                        <Box
                                            textTransform="capitalize"
                                            fontFamily={"poppins"}
                                            fontSize={16}
                                            fontWeight={500}
                                            color={"white"}
                                            display="flex"
                                            gap="10px"
                                        >
                                            {column.render("Header")}
                                            <FaSort />
                                        </Box>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody height="70px" {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <Tooltip label = {cell.value} hasArrow arrowSize={15} placement='top-start' margin={-4} color="gray.800" bg="gray.100">
                                                <Td
                                                    color={"#757998"}
                                                    fontWeight={600}
                                                    style={
                                                        cell?.column?.id === 'actions'
                                                            ? {
                                                                whiteSpace:
                                                                    "nowrap",
                                                                maxWidth: 220,
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }
                                                            : {whiteSpace:
                                                                    "nowrap",
                                                                maxWidth: 10,
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",}
                                                    }
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </Td>
                                            </Tooltip>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            {/* Pagination */}
           <Flex justifyContent="space-between">
               <Flex justifyContent="start" m={4} alignItems="center">
                   <Flex>
                       {/* <Tooltip label="First Page">
                        <IconButton
                            size="sm"
                            variant="ghost"
                            onClick={() => gotoPage(0)}
                            isDisabled={!canPreviousPage}
                            background={
                                !canPreviousPage
                                    ? "#A7A9BD !important"
                                    : "#79A9FF !important"
                            }
                            icon={
                                <FiChevronsLeft
                                    color={
                                        !canPreviousPage ? "#A7A9BD" : "#FFFFFF"
                                    }
                                    h={3}
                                    w={3}
                                />
                            }
                            mr={4}
                        />
                    </Tooltip> */}
                       <Tooltip label="Previous Page">
                           <IconButton
                               size="sm"
                               variant="ghost"
                               color="white !important"
                               onClick={previousPage}
                               isDisabled={!canPreviousPage}
                               background={
                                   !canPreviousPage
                                       ? "#F8F9FA !important"
                                       : "#3B82F6 !important"
                               }
                               icon={
                                   <GrLinkPrevious
                                       color={
                                           !canPreviousPage ? "#A7A9BD" : "#FFFFFF"
                                       }
                                       h={6}
                                       w={6}
                                   />
                               }
                           />
                       </Tooltip>
                   </Flex>
                   <Flex alignItems="center" mx={3}>
                       <Text flexShrink="0">
                           {/* Page{" "} */}
                           <Text
                               borderColor="#3B82F6"
                               borderRadius={5}
                               color="#3B82F6"
                               px="18px"
                               py="3px"
                               borderWidth={2}
                               fontWeight="bold"
                               as="span"
                           >
                               {pageIndex + 1}
                           </Text>{" "}
                           <span style={{ margin: "auto 8px", color: "#3B82F6" }}>
                            /
                        </span>
                           <Text p={1} color="#3B82F6" fontWeight="bold" as="span">
                               {pageOptions.length}
                           </Text>
                       </Text>
                   </Flex>
                   <Flex>
                       <Tooltip label="Next Page">
                           <IconButton
                               size="sm"
                               variant="ghost"
                               onClick={nextPage}
                               isDisabled={!canNextPage}
                               background={
                                   !canNextPage
                                       ? "#F8F9FA !important"
                                       : "#3B82F6 !important"
                               }
                               icon={
                                   <GrLinkNext
                                       color={!canNextPage ? "#FFFFFF" : "#FFFFFF"}
                                       h={6}
                                       w={6}
                                   />
                               }
                           />
                       </Tooltip>
                       {/* <Tooltip label="Last Page">
                        <IconButton
                            size="sm"
                            variant="ghost"
                            onClick={() => gotoPage(pageCount - 1)}
                            isDisabled={!canNextPage}
                            background={
                                !canNextPage ? "#A7A9BD" : "#79A9FF !important"
                            }
                            icon={
                                <FiChevronsRight
                                    color={!canNextPage ? "#A7A9BD" : "#FFFFFF"}
                                    h={3}
                                    w={3}
                                />
                            }
                            ml={4}
                        />
                    </Tooltip> */}
                   </Flex>
               </Flex>
               {auditTrue ?
                   <Button
                       colorScheme="blue"
                       backgroundColor="#3B82F6"
                       borderRadius={4}
                       px="10"
                       marginTop={5}
                       rightIcon={<MdFileDownload/>}
                   >
                       Download Data
                   </Button> : ''}
           </Flex>
        </>
    );
};

export default Datatable
