import {
    Button,
    css,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Box
} from "@chakra-ui/react";
import * as React from "react";
import Success from "./images/success.png";

const DeleteModel = ({heading, name, isOpen, onClose, onDestory }) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onDestory} size="md" >
            <ModalOverlay />
            <ModalContent position="relative" padding={"30px "}>
                <Text cursor={"pointer"}  onClick={onDestory} style={{ fontSize: "32px",  position: "absolute", top: "5%", right: "5%" }}> &times; </Text>
                <ModalBody
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    })}
                >
                    <Heading size="md" margin="15px 0">
                        {heading}?
                    </Heading>
                    <Box textAlign={"center"}>
                        <Text>Are you sure you want to delete "{name}"? </Text>
                    <   Text> You can't undo this action. </Text>
                    </Box>
                </ModalBody>
                <Flex justifyContent={"center"} gap={"20px"} marginBottom={"30px"} marginTop={"30px"}>
                    <Button
                        width={"35%"}
                        variant={"outline"}
                        onClick={onDestory}
                        borderRadius={"4px"}
                        padding={"10px 40px"}
                    >
                        Back
                    </Button>
                    <Button
                        width={"35%"}
                        css={css({
                            background: "#F9665E !important",
                            color: "white",
                        })}
                        padding={"10px 40px"}
                        borderRadius={"4px"}
                        onClick={onClose}
                    >
                        Delete Media
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default DeleteModel;
