import React, {useEffect, useState} from 'react';
import {Flex, Heading} from "@chakra-ui/layout";
import '../../pages/AdCampaigns/adCampaign.css'
import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select
} from "@chakra-ui/react";
import {Geography} from "../../pages/CampaignBriefs/constant/SelectValues";
import {Input} from "@chakra-ui/input";
import axios from "../../helpers/axios";
import {ChevronDownIcon, CloseIcon} from "@chakra-ui/icons";

function CampaignGeography({
                               selectedGeography,
                               handleSelectedGeography,
                               setLocation,
                               location,
                           }) {

    const [showCountry, setShowCountry] = useState(false);
    const [showProvince, setShowProvince] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showZipCode, setShowZipCode] = useState(false);
    const [countries, setCountries] = useState([])
    const [province, setProvince] = useState([])
    const [city, setCity] = useState([])

    useEffect(() => {
        if (selectedGeography) {
            if (selectedGeography === 'Country') {
                setShowCountry(true)
                setShowProvince(false)
                setShowCity(false)
                setShowZipCode(false)
            } else if (selectedGeography === 'Province') {
                setShowCountry(true)
                setShowProvince(true)
                setShowCity(false)
                setShowZipCode(false)
            } else if (selectedGeography === 'City') {
                setShowCountry(true)
                setShowProvince(false)
                setShowCity(true)
                setShowZipCode(false)
            } else {
                setShowCountry(true)
                setShowProvince(true)
                setShowCity(true)
                setShowZipCode(true)
            }
        }
    }, [selectedGeography])

    const method = 'get';
    const headers = {
        'X-CSCAPI-KEY': process.env.REACT_APP_GEOGRAPHY_API_KEY
    }

    const config = {method, headers}

    useEffect(() => {
        axios("https://api.countrystatecity.in/v1/countries", config).then((res) => {
            setCountries(res.data)
        })
    }, [])

    useEffect(() => {
        if (location.country) {
            if(selectedGeography.toLowerCase() === 'city'){
                axios(`https://api.countrystatecity.in/v1/countries/${location.country}/cities`, config).then((res) => {
                    setCity(res.data)
                })
            }
            if(selectedGeography.toLowerCase() === 'province'){
                axios(`https://api.countrystatecity.in/v1/countries/${location.country}/states`, config).then((res) => {
                    setProvince(res.data)
                })
            }
        }
    }, [location.country,selectedGeography]);

    function handleRemoveOptions(e) {
        e.stopPropagation()
        setLocation({...location, province: []})
    }

    function handleChecked(e) {
        const {checked, value} = e.target;

        if (checked) {
            const newSelectedProvince = [...location.province, value];
            setLocation({...location, province: newSelectedProvince})

        } else {
            const filteredOptions = location?.province?.filter((e) => e !== value);
            setLocation({...location, province: filteredOptions})
        }
    }

    function handleRemoveCity(e) {
        e.stopPropagation()
        setLocation({...location, city: []})
    }

    function handleCheckedCity(e) {
        const {checked, value} = e.target;

        if (checked) {
            const newSelectedCity = [...location.city, value];
            setLocation({...location, city: newSelectedCity})

        } else {
            const filteredOptions = location?.city?.filter((e) => e !== value);
            setLocation({...location, city: filteredOptions})
        }
    }

    return (
        <div>
            <div style={{margin: "8px 0"}}>
                <Heading fontSize={"lg"} fontFamily='poppins'>CampaignGeography</Heading>
                <FormControl maxWidth={440} mt={6} isRequired>
                    <FormLabel color='#363849'>Add Location</FormLabel>
                    <Select
                        id="location"
                        name="location"
                        placeholder="Select"
                        variant="outline"
                        border="2px"
                        fontWeight={500}
                        borderRadius={1}
                        borderColor="#E7E7E9"
                        value={selectedGeography}
                        onChange={handleSelectedGeography}
                    >
                        {Geography.map((objective) => (
                            <option className='campaign-option' key={objective.label} value={objective.value}>
                                {objective.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                {showCountry && <GridItem>
                    <FormControl maxWidth={440} mt={4} isRequired>
                        <FormLabel>Country</FormLabel>
                        <Select
                            id="country"
                            name="country"
                            placeholder="Select Country"
                            variant="outline"
                            border="2px"
                            fontWeight={500}
                            borderRadius={0}
                            borderColor="#e7e7e9"
                            value={location.country}
                            onChange={(e) => setLocation({...location, country: e.target.value, city: [], province: []})}
                        >
                            {countries.map((objective) => (
                                <option key={objective.id} value={objective.iso2}>
                                    {objective.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>}
                {showProvince && <GridItem>
                    <FormControl maxWidth={440} mt={4} isRequired>
                        <FormLabel>Province</FormLabel>
                        {/*<Select*/}
                        {/*    id="province"*/}
                        {/*    name="province"*/}
                        {/*    placeholder="Select Country"*/}
                        {/*    variant="outline"*/}
                        {/*    border="2px"*/}
                        {/*    fontWeight={500}*/}
                        {/*    borderRadius={0}*/}
                        {/*    borderColor="#e7e7e9"*/}
                        {/*    value={location.province}*/}
                        {/*    onChange={(e) => setLocation({...location, province: e.target.value})}*/}
                        {/*>*/}
                        {/*    {province.map((objective) => (*/}
                        {/*        <option key={objective.id} value={objective.iso2}>*/}
                        {/*            {objective.name}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</Select>*/}
                        <Menu closeOnSelect={false}>
                            <MenuButton borderRadius='md'
                                        borderWidth='2px'
                                        textAlign={'left'}
                                        bg={''}
                                        _hover={{bg: ''}}
                                        _expanded={{bg: ''}}
                                        _focus={{boxShadow: 'outline'}} width={'full'} fontWeight={500}
                                        as={Button} rightIcon={<Flex alignItems={'center'} gap={2}>
                                <CloseIcon fontSize={12} onClick={handleRemoveOptions}/>
                                <ChevronDownIcon fontSize={22}/>
                            </Flex>}>
                                <span style={{
                                    display: "block",
                                    width: "100%",
                                    fontSize: "14px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {!!location?.province?.length ? location?.province?.join(", ") : "Select Province"}
                                </span>
                            </MenuButton>
                            <MenuList minWidth={616} maxHeight={260} overflow={"auto"}>
                                {province && province.length > 0 && province.map((item) => (
                                    <MenuItem fontWeight={500}><Checkbox
                                        isChecked={location?.province?.includes(item.name)}
                                        value={item.name}
                                        onChange={(e) => handleChecked(e)}>{item.name}</Checkbox></MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                    </FormControl>
                </GridItem>}
                {showCity && <GridItem>
                    <FormControl maxWidth={440} mt={4} isRequired>
                        <FormLabel>City</FormLabel>
                        {/*<Select*/}
                        {/*    id="city"*/}
                        {/*    name="city"*/}
                        {/*    placeholder="Select City"*/}
                        {/*    variant="outline"*/}
                        {/*    border="2px"*/}
                        {/*    fontWeight={500}*/}
                        {/*    borderRadius={0}*/}
                        {/*    borderColor="#e7e7e9"*/}
                        {/*    value={location.city}*/}
                        {/*    onChange={(e) => setLocation({...location, city: e.target.value})}*/}
                        {/*>*/}
                        {/*    {city.map((objective) => (*/}
                        {/*        <option key={objective.id} value={objective.name}>*/}
                        {/*            {objective.name}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</Select>*/}
                        <Menu closeOnSelect={false}>
                            <MenuButton borderRadius='md'
                                        borderWidth='2px'
                                        textAlign={'left'}
                                        bg={''}
                                        _hover={{bg: ''}}
                                        _expanded={{bg: ''}}
                                        _focus={{boxShadow: 'outline'}} width={'full'} fontWeight={500}
                                        as={Button} rightIcon={<Flex alignItems={'center'} gap={2}>
                                <CloseIcon fontSize={12} onClick={handleRemoveCity}/>
                                <ChevronDownIcon fontSize={22}/>
                            </Flex>}>
                                <span style={{
                                    display: "block",
                                    width: "100%",
                                    fontSize: "14px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {!!location?.city?.length ? location?.city?.join(", ") : "Select City"}
                                </span>
                            </MenuButton>
                            <MenuList minWidth={616} maxHeight={260} overflow={"auto"}>
                                {city && city.length > 0 && city.map((item) => (
                                    <MenuItem fontWeight={500}><Checkbox
                                        isChecked={location?.city?.includes(item.name)}
                                        value={item.name}
                                        onChange={(e) => handleCheckedCity(e)}>{item.name}</Checkbox></MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                    </FormControl>
                </GridItem>}
                {showZipCode && <GridItem>
                    <FormControl isRequired maxWidth={440} mt={4}>
                        <FormLabel>Zip Code</FormLabel>
                        <Input name="zipCode" borderColor="#e7e7e9"
                               value={location.zipCode}
                               onChange={(e) => setLocation({...location, zipCode: e.target.value})}
                               borderRadius={1} color={"#757998"}
                               fontWeight={500}/>
                    </FormControl>
                </GridItem>}</Grid>
        </div>
    );
}

export default CampaignGeography;
