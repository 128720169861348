import React, {useEffect, useState} from 'react';
import {Heading} from "@chakra-ui/layout";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Select,
    Text,
    useToast
} from "@chakra-ui/react";
import CampaignModal from "../../layout/components/CampaignModal";
import {useGetClientBusiness} from "../../hooks/clients/useGetClientBusiness";
import {useDispatch, useSelector} from "react-redux";
import instance from "../../helpers/axios";
import * as yup from "yup";
import {Form, Formik} from "formik";
import InputBox from "../../components/InputBox";
import {
    settingCampaignBudget,
    settingCampaignDetails,
    settingDemographics
} from "../../store/adCampaign";
import './adCampaign.css'
import _ from 'lodash'

const initialValue = {
    campaignName: "",
}

const schema = yup.object().shape({
    campaignName: yup.string().trim().min(3).required().label('Campaign name'),
});

function CampaignDetails({activeStep, prevStep, nextStep, handleNotes, getSingleBusiness}) {

    const dispatch = useDispatch();

    const {campaignDetails, campaignBudget, demographics, businessDetails} = useSelector((state) => state.adCampaign)
    const [initialFormValue, setInitialFormValue] = useState(initialValue)

    const [open, setOpen] = useState(false)
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [business, setBusiness] = useState([])
    const clientId = useSelector((state) => state.client.clientId);
    const toast = useToast()

    const {data, refetch} = useGetClientBusiness(clientId);

    useEffect(()=> {
       if(campaignDetails.campaignName){
           setInitialFormValue(campaignDetails)
       }else {
           setInitialFormValue(initialValue)
       }
    },[campaignDetails])

    useEffect(() => {
        refetch();
    }, [clientId]);

    useEffect(() => {
        setBusiness(data?.businesses);
        if(_.isEmpty(businessDetails) && !!data?.businesses){
            setSelectedBusiness(data?.businesses[0]?.id)
        }
    }, [data]);

    useEffect(() => {
        if(!_.isEmpty(businessDetails)){
            setSelectedBusiness(businessDetails.id)
        }
    },[businessDetails])

    const handleBusinessSelect = (e) => {
        setSelectedBusiness(e.target.value);
        getSingleBusiness(e.target.value)
        dispatch(settingCampaignBudget({...campaignBudget, selectedChannels: []}))
        dispatch(settingDemographics({...demographics, audience: ""}))
        if (e.target.value === 'add-business') {
            handleOpenDialog()
        }
    };

    function handleOpenDialog() {
        setOpen(true)
    }

    function handleCloseDialog() {
        setOpen(false)
        getSingleBusiness(business[0].id)
    }

    const handleAddNewBusiness = async (values) => {
        await instance({
            method: "POST",
            url: `client/${clientId}/business/new`,
            data: {
                name: values.name,
                contactName: values.contactName,
                phone: values.phone,
                email: values.email,
                industry: values.industry,
                address: values.address
            },
        })
            .then((response) => {
                handleCloseDialog()
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()
                getSingleBusiness(response.data.data.id)
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    function onSubmit(values) {
        dispatch(settingCampaignDetails({...values}))
        getSingleBusiness(selectedBusiness)
        nextStep()
    }

    return (
        <div>
            <Heading mt={5} fontSize="3xl" fontFamily='poppins'>Campaign Details</Heading>
            <Formik initialValues={initialFormValue}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    enableReinitialize>
                {({values, errors}) => (
                    <Box as={Form} noValidate fontFamily={"poppins"}>
                        <Grid  templateColumns='repeat(2, 1fr)' gap={6} mt={10} height={460}>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel color='#363849' >Business Name</FormLabel>
                                    <Select
                                        id="detail.objective"
                                        name="detail.objective"
                                        placeholder={business?.length === 0 && "Select Business"}
                                        variant="outline"
                                        border="2px"
                                        color='#363849'
                                        fontWeight={500}
                                        borderRadius={1}
                                        borderColor="#E7E7E9"
                                        value={selectedBusiness}
                                        onChange={handleBusinessSelect}
                                    >
                                        {!!business?.length ? (
                                            <>
                                                {business.map((objective) => (
                                                    <option className='campaign-option' key={objective.id} value={objective.id}>
                                                        {objective.name}
                                                    </option>
                                                ))}
                                                <option className='add-campaign-option' key="add-business" value="add-business">
                                                    Add New Business +
                                                </option>
                                            </>
                                        ) : <option className='add-campaign-option' key="add-business" value="add-business">
                                            Add New Business +
                                        </option>}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel color='#363849'>Campaign Name</FormLabel>
                                    <InputBox name="campaignName" borderColor="#A7A9BD"
                                              fontSize={'md'}
                                              border="2px"
                                              borderRadius={1}
                                              />
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Divider my={8}/>
                        <div style={{display: 'flex', justifyContent: "space-between"}}>
                            <Button fontWeight={400} fontFamily={"poppins"} leftIcon={<Image
                                src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                                height="18px"
                            />} onClick={handleNotes}>Add Notes</Button>
                            <div style={{display: 'flex', justifyContent:"flex-end"}}>
                                <Button mr={4} px={8} py={5} _hover={{ bg: '' }} color={"#1F95FF"} bgColor={"#FFFFFF"} border={"2px"} borderColor="#1F95FF" onClick={() => prevStep()} isDisabled={activeStep === 0}>Back</Button>
                                <Button type='submit' _hover={{ bg: '' }} py={5} color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"} borderColor="#1F95FF"  px={8}>Next</Button>
                            </div>
                        </div>
                    </Box>)}
            </Formik>

            <CampaignModal onClose={handleCloseDialog}
                           reportData={{name: "", contactName: "", email: '', phone: "", industry: "", address: ""}}
                           buttonType={'Save'} onSubmit={handleAddNewBusiness} dialogTitle={"Add Another Business"}
                           isOpen={open}/>

        </div>
    );
}

export default CampaignDetails;