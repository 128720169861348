import {
    Box,
    Button,
    Center,
    css,
    Flex,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    VStack,
    ChakraProvider, extendTheme,
    SimpleGrid, Image
} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../../../layout/constant/MenuList";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSocialMedia} from "../../../../../store/adUploads";

const socialMediaPlatform = [
    {
        name: "Facebook",
        image: "facebook.svg",
        adType: "facebook"
    },
    {
        name: "Google",
        image: 'google.svg',
        adType: "google"
    },
    {
        name: "Instagram",
        image: 'instagram.svg',
        adType: "instagram"
    },
    {
        name: "Tiktok",
        image: 'tiktok.png',
        adType: "tiktok"
    },
    {
        name: "Pinterest",
        image: 'pinterest.png',
        adType: "pinterest"
    },
    {
        name: "Display",
        image: 'display.png',
        adType: "dv360"
    }
]

function SocialPlatform({activeStep, steps, prevStep, nextStep, label}) {


    function handleClick(index, adType) {
        handleSelect(index, adType)
        nextStep()
    }


    const dispatch = useDispatch();

    const {selectedSocialMedia, mediaType} = useSelector((state) => state.adUpload)
    const [selectedTab, setSelectTab] = useState(selectedSocialMedia);

    function handleSelect(index, adType) {
        setSelectTab(adType);
        dispatch(selectSocialMedia(adType))
    }

    const activeTab = {
        border: "2px solid #3B82F6",
        boxSizing: "border-box"
    }

    return (
        <Box w='100%' p={4}>
            <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                <Heading fontSize="xl" color={"#757998"}>
                    What platform is this ad set for?
                </Heading>
                <Heading fontSize="md" color="gray.400">
                    5 steps to complete
                </Heading>
            </Box>

            <SimpleGrid columns={[1, 1, 1, 2]} spacing='20px'>
                {
                    socialMediaPlatform.map((item, index) => {
                        const {adType, image, name} = item
                        return (
                            <Box cursor={"pointer"} border={"2px solid rgba(167, 169, 189, 0.2)"}  style={selectedTab === adType ? activeTab : null} borderRadius={"10px"} height='80px' display={"flex"} p={4} key={item.name} onClick={() => handleClick(index, adType)}>
                                <Box display={"flex"} alignItems={"center"} gap={"10px"} color={"#757998"}>
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/images/${image}`}
                                        height="40px"
                                    /> {name}</Box>
                            </Box>
                        )
                    })
                }
            </SimpleGrid>
            <Flex width="100%" justify="flex-end" py={10}>
                <Button mr={4} isDisabled={true} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                    Next
                </Button>
                {/*<Button*/}
                {/*    isDisabled={activeStep === 0}*/}
                {/*    mr={4}*/}
                {/*    onClick={prevStep}*/}
                {/*    colorScheme='blue' variant='outline' borderRadius={0} px={10}*/}
                {/*>*/}
                {/*    Prev*/}
                {/*</Button>*/}

                {/*{*/}
                {/*    label === "Social Platform" && !(activeStep === steps.length - 1) && (*/}
                {/*        <Button mr={4} isDisabled={selectedSocialMedia ? false : true} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>*/}
                {/*            Next*/}
                {/*        </Button>*/}
                {/*    )*/}
                {/*}*/}

            </Flex>
        </Box>
    )
}

export default SocialPlatform
