import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Flex,
    Heading,
    Box,
    Button,
    Center,
    Tabs,
    TabList,
    css,
    Tab,
    useDisclosure,
    TabPanel,
    TabPanels,Grid,GridItems
} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {CAMPAIGN_BRIEFS_TABS, ROLES} from "../../../../constant";
import {For} from "react-haiku";
import {useDispatch, useSelector} from "react-redux";
import {useGetClientDetailsOnClick} from "../../../../hooks/clients/useGetClientDetails";
import {useUpdateCampaign} from "../../../../hooks/campaign-briefs/useUpdateCampaign";
import {useGetCampaign} from "../../../../hooks/campaign-briefs/useGetCampaign";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../atoms/authAtom";
import CreateForm from "../CampaignDetails/CreateForm";
import AdUploadList from "./AdUploadList";
import AuditLogsList from "../AuditLogsList";




function CreateAdUpload(props) {

    const [tabIndex, SetTabIndex] = useState(0);
    const [isRemoveTabs, SetIsRemoveTabs] = useState(null);
    const dispatch = useDispatch();
    const {
        isOpen: isFacebookModelOpen,
        onOpen: onFacebookModelOpen,
        onClose: onFacebookModelClose,
    } = useDisclosure();
    const {
        isOpen: isDV360odelOpen,
        onOpen: onDV360ModelOpen,
        onClose: onDV360ModelClose,
    } = useDisclosure();

    const clientId = useSelector((state) => state.client.clientId);

    const navigate = useNavigate();
    const location = useLocation();

    const {id} = useParams();
    const {mutate, data} = useGetClientDetailsOnClick();
    const {mutate: updateCampaignStatus} = useUpdateCampaign();
    const {data: campaignData, mutate: mutateGetCampaign} = useGetCampaign();
    const {
        access_info: {roles},
    } = useRecoilValue(profile);

    useEffect(() => {
        if (location.state && location.state.isShowUpload) {
            setTimeout(() => {
                SetTabIndex(5);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (roles) {
            let isMatch = roles.find((item) => item.includes(ROLES.CLIENT));
            if (isMatch) {
                SetIsRemoveTabs(false)
            } else {
                SetIsRemoveTabs(true)
            }
        }
    }, [roles]);


    return (
        <div>
            <Flex templateColumns="repeat(6, 1fr)" gap={4}>
                <Box>
                    <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                        <Flex onClick={() => navigate("/campaign-brief")} cursor={"pointer"}>
                                <Button
                                    color='#79A9FF'
                                    variant='outline'
                                    fontFamily={"poppins"}
                                    fontSize={14}
                                    lineHeight={21}
                                    leftIcon={<ArrowBackIcon/>}
                                >
                                    Back
                                </Button>
                        </Flex>
                        <Heading fontSize="4xl" mb={4} color={TEXT_COLOR}>
                            Campaign name Ad set
                        </Heading>
                        <Tabs
                              size="sm"
                              w="full"
                              colorScheme="black"
                              overflow="hidden">
                            <TabList overflowX="auto"
                                     css={css({
                                             scrollbarWidth: "none",
                                             "::-webkit-scrollbar": {display: "none"},
                                             WebkitOverflowScrolling: "touch",
                                             boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                                             border: "0 none"})}>
                                <For
                                    each={CAMPAIGN_BRIEFS_TABS}
                                    render={(tab, index) => {
                                        const isShowTab = (tab.hideTabForClient && !isRemoveTabs) ? false : true
                                        if (isShowTab) {
                                            return (
                                                <Tab
                                                    key={index}
                                                    onClick={() => {
                                                        SetTabIndex(index);
                                                    }}
                                                    whiteSpace="nowrap"
                                                    isDisabled={
                                                        !id &&
                                                        (tab.title === "AD uploads" || tab.title === "Comments")
                                                    }
                                                >
                                                    {tab.title}
                                                </Tab>
                                            );
                                        }
                                    }}
                                />
                            </TabList>
                            <TabPanels>
                                <Grid>

                                </Grid>
                            </TabPanels>
                        </Tabs>
                    </Heading>
                </Box>
            </Flex>
        </div>
    );
}

export default CreateAdUpload;