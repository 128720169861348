import {
    Box, Button,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, IconButton, SimpleGrid, Text,
} from "@chakra-ui/react";
import {
  CheckboxContainer,
  CheckboxControl,
  SelectControl,
} from "formik-chakra-ui";
import InputBox from "../../../../components/InputBox";
import TextAreaBox from "../../../../components/TextAreaBox";
import {
  CampaignKPI,
  CampaignObjective,
  CampaignPriceModel,
} from "../../../../constant";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import {Flex} from "@chakra-ui/layout";
import React, { useState, useEffect} from "react";
import {CampaignBudgetOptions} from "../../constant/SelectValues";
import MultiSelectInputBox from "../../../../components/MultiSelectInputBox";
import {AddIcon, CloseIcon} from "@chakra-ui/icons";

const CampaignMetricsStep = ({ errors, activeStep, steps, prevStep, nextStep }) => {

    const [isError, setIsError] = useState(false)
    let [objectiveValue, setObjectiveValue] = useState([]);
    // console.log("objectiveValue",objectiveValue)


    useEffect(() => {
        if(errors?.detail?.objective?.length > 0) {
            setIsError(true);
        } else if(errors?.detail?.conversionMeasurement?.length > 0) {
            setIsError(true);
        }else if(errors?.detail?.customMetricsAndGoals?.length > 0) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    },[errors?.detail?.objective, errors?.detail?.conversionMeasurement, errors?.detail?.customMetricsAndGoals])

  return (
    <Box p={4}>
      <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
      <Heading fontSize="xl" color={"#757998"}>
        Campaign Metrics
      </Heading>
      <Heading fontSize="md" color="#A7A9BD">
        5 steps to complete
      </Heading>
      </Box>
      <Grid className="flex-for-mobile"  templateColumns="repeat(2, 1fr)" gap={"8px"}>
        <GridItem colSpan={2}>
          <FormLabel htmlFor="detail.priceModel" color="gray" fontSize="sm">
            Price Model
          </FormLabel>
          <Grid className="flex-for-mobile" templateColumns="repeat(2, 1fr)" gap={4}>
            {CampaignPriceModel.map((priceModal, index) => (
              <GridItem key={index} textAlign={"left"} colSpan={1}>
                <CheckboxControl
                  key={index}
                  colorScheme="pink"
                  name="detail.priceModel"
                  value={priceModal.value}
                >
                  {priceModal.label}
                </CheckboxControl>
              </GridItem>
            ))}
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <CheckboxContainer
            name="detail.Kpis"
            label="KPIs"
            stackProps={{
              padding: 0,
              direction: { base: "column", lg: "row" },
            }}
            labelProps={{
              fontSize: "sm",
              color: "gray",
            }}
          >
            {CampaignKPI.map((priceModal, index) => (
              <CheckboxControl
                key={index}
                colorScheme="pink"
                name="detail.Kpis"
                value={priceModal.value}
              >
                {priceModal.label}
              </CheckboxControl>
            ))}
          </CheckboxContainer>
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.customMetricsAndGoals"
            placeholder="Metrics/Goals to Reach & Exceed*"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <TextAreaBox
            name="detail.campaignConverterNotes"
            placeholder="Campaign Converters"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CheckboxContainer
            name="detail.campaignConverters"
            stackProps={{
              padding: 0,
              direction: { base: "column", xl: "row" },
              gap: 6,
            }}
            labelProps={{
              fontSize: "sm",
              color: "gray",
            }}
          >
            {[
              { value: "Include", label: "Include Converters" },
              { value: "Exclude", label: "Exclude Converters" },
            ].map((priceModal, index) => (
              <CheckboxControl
                key={index}
                colorScheme="pink"
                name="detail.campaignConverters"
                value={priceModal.value}
              >
                {priceModal.label}
              </CheckboxControl>
            ))}
          </CheckboxContainer>
        </GridItem>
        <GridItem >
          <FormControl>
            <FormLabel htmlFor="detail.objective" color="gray" fontSize="sm">
              What is the main objective for this campaign?
            </FormLabel>
            <SelectControl
              id="detail.objective"
              name="detail.objective"
              selectProps={{
                placeholder: "Select objective",
                variant: "outline",
                border: "2px",
                borderRadius: 0,
                borderColor: "gray",
              }}
            >
              {CampaignObjective.map((objective) => (
                <option key={objective.value} value={objective.value}>
                  {objective.label}
                </option>
              ))}

            </SelectControl>
              {/*<MultiSelectInputBox*/}
              {/*    name="Campaign Objective"*/}
              {/*    options={CampaignObjective}*/}
              {/*    value={objectiveValue}*/}
              {/*    placeholder={`Select Campaign Objective`}*/}
              {/*    onChange={(e) => {*/}
              {/*        setObjectiveValue(e);*/}
              {/*    }}*/}
              {/*/>*/}
              {/*{objectiveValue.length !== 0 && objectiveValue.map((obj) => {*/}
              {/*    return (*/}
              {/*        <Flex marginTop={3} gap={3} alignItems="center">*/}
              {/*            <IconButton*/}
              {/*                size="sm"*/}
              {/*                variant="raised"*/}
              {/*                isRound*/}
              {/*                color="white !important"*/}
              {/*                background="#FF8189"*/}
              {/*                cursor= "default"*/}
              {/*                icon={<AddIcon color="#ffffff"/>}*/}
              {/*            />*/}
              {/*            <Text>{obj.label}</Text>*/}
              {/*        </Flex>*/}
              {/*    )*/}
              {/*})}*/}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <TextAreaBox
            name="detail.conversionMeasurement"
            placeholder="What is being measured and how will the campaign be assessed?*"
          />
        </GridItem>
          {/*<GridItem colSpan={2}>*/}
          {/*    <InputBox*/}
          {/*        name="detail.campaignBasic.notes"*/}
          {/*        type="url"*/}
          {/*        placeholder={"Notes"}*/}
          {/*    />*/}
          {/*</GridItem>*/}
      </Grid>
        <Flex width="100%" justify="flex-end" py={10}>
            <Button
                mr={4}
                onClick={prevStep}
                colorScheme='blue' variant='outline' borderRadius={0} px={10}
            >
                Back
            </Button>

            <Button mr={4} isDisabled={isError} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                Next
            </Button>

        </Flex>
    </Box>
  );
};

export default CampaignMetricsStep;
