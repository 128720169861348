import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Heading
} from "@chakra-ui/react";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import {
    dv360AdUploadalueLengths,
    dv360AdUploadInitialValues, facebookVideoAdValueLengths, fbCarouselAdUploadValueLengths,
    pinterestAdUploadvalueLengths
} from "../../../../../constant/InitialValues";
import {CreativeType, facebookAccountIds, hostingSource} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import{settingInstagramVideoForm,settingInstagramVideoMedia} from "./store"
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
    adName: "",
    primaryText:"",
    type:"",
    link:"",
}

const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    type: yup.string().required().label('Type'),
    link: yup.string().trim().required("Landing page url is required.").label("Landing page url")
        .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            "Landing page url is not valid!"
        ),
});

function InstagramVideoAdDetails({activeStep, steps, prevStep, nextStep, label}) {
    const {id, adUploadId} = useParams();

    const clientId = useSelector((state) => state.client.clientId);
    const { formDetails } = useSelector((state) => state.instagramVideoAd)

    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

    useEffect(() => {
        if(adUploadId) {
            refetch()
        }
    }, [adUploadId])

    useEffect(() => {
        if(adUploadId){
            // const imageFile = data?.adUpload?.detail?.fileInfoList.map((el) => el.files);
            dispatch(settingInstagramVideoForm({...data?.adUpload?.detail, adName: data?.adUpload?.name,primaryText: data?.adUpload?.detail?.message,link: data?.adUpload?.detail?.link, type:data?.adUpload?.detail?.call_to_action?.type}))
            dispatch(settingInstagramVideoMedia({ thumbnailURL: data?.adUpload?.detail?.thumbnailFile, thumbnailFile: data?.adUpload?.detail?.image_url,videoURL:data?.adUpload?.detail?.videoFile,videoFile:data?.adUpload?.detail?.video_id }))
            // dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
        }
    }, [data])

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(settingInstagramVideoForm({...data?.adUpload?.detail,...values}))
            nextStep()
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue('adName', formDetails.adName || "")
        setFieldValue('primaryText', formDetails.primaryText || "")
        setFieldValue('type', formDetails.type || "")
        setFieldValue('link', formDetails.link || "")
    }, [formDetails])


    return (
        <>
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            3 step to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please fill in your ad details.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            3 steps to complete
                        </Heading>
                    </Box>
                )
            }


            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        gap={4}
                        className="fb-upload"
                    >
                        <GridItem
                            w="full"
                            colSpan={{base: 6, lg: 4}}
                        >
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="adName" color="gray" fontSize="sm" display={"none"}>
                                        Ad Name - max 512 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="adName"
                                            name="adName"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder:"  Ad Name - max 512 characters"
                                            }}
                                            onChange={(e)=>setFieldValue('adName',e.target.value)}
                                        />
                                        <TooltipComponent text={"The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."} />

                                        <CircularProgress
                                            max={facebookVideoAdValueLengths.adName}
                                            value={values.adName.length}
                                            color={
                                                values.adName.length > facebookVideoAdValueLengths.adName
                                                    ? "red.400"
                                                    : "green.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values.adName.length >
                                                facebookVideoAdValueLengths.adName
                                                    ? facebookVideoAdValueLengths.adName -
                                                    values.adName.length
                                                    : values.adName.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="primaryText"
                                        color="gray"
                                        fontSize="sm"
                                        display={"none"}
                                    >
                                        Primary Text - max 125 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="primaryText"
                                            name="primaryText"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "Primary Text - max 125 characters"
                                            }}
                                            onChange={(e)=>setFieldValue('primaryText',e.target.value)}
                                        />
                                        <CircularProgress
                                            max={facebookVideoAdValueLengths.primaryText}
                                            value={values.primaryText.length}
                                            color={
                                                values.primaryText.length > facebookVideoAdValueLengths.primaryText
                                                    ? "red.400"
                                                    : "blue.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values.primaryText.length >
                                                facebookVideoAdValueLengths.primaryText
                                                    ? facebookVideoAdValueLengths.primaryText -
                                                    values.primaryText.length
                                                    : values.primaryText.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="link"
                                        color="gray"
                                        fontSize="sm"
                                        display={"none"}
                                    >
                                        Landing Page URL - max 255 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="link"
                                            name="link"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "Landing Page URL - max 255 characters"
                                            }}
                                            onChange={(e)=>setFieldValue('link',e.target.value)}
                                        />
                                        <CircularProgress
                                            max={facebookVideoAdValueLengths.link}
                                            value={values.link.length}
                                            color={
                                                values.link.length > facebookVideoAdValueLengths.link
                                                    ? "red.400"
                                                    : "blue.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values.link.length >
                                                facebookVideoAdValueLengths.link
                                                    ? facebookVideoAdValueLengths.link -
                                                    values.link.length
                                                    : values.link.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="type"
                                        color="gray"
                                        fontSize="sm"
                                    >
                                        Call to Action
                                    </FormLabel>
                                    <div className="input-box">
                                        <SelectControl
                                            id="type"
                                            name="type"
                                            selectProps={{
                                                placeholder:
                                                    "-- Select One --",
                                                variant: "outline",
                                                border: "2px",
                                                borderRadius: 0,
                                                borderColor: "gray",
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                                color: "#757998",
                                                marginRight:
                                                    "100px",
                                            }}
                                            onChange={(e) => {
                                                setFieldValue('type', e.target.value);
                                            }}
                                        >
                                            {facebookAccountIds.map(
                                                (el) => (
                                                    <option
                                                        value={
                                                            el.key
                                                        }
                                                        key={el.key}
                                                    >
                                                        {el.name}
                                                    </option>
                                                )
                                            )}
                                        </SelectControl>
                                        <CircularProgress
                                            opacity={0}
                                        >
                                            <CircularProgressLabel>
                                                0
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>

                        </GridItem>
                    </Grid>
                    <Flex width="100%" justify="flex-end" py={10}>
                        {
                            !adUploadId &&  <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                        }
                        {
                            label === "Ad Details" && !(activeStep === steps.length - 1) && (
                                <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                    Next
                                </Button>
                            )
                        }
                    </Flex>
                </Form>
            </FormikProvider>

        </>
    )
}

export default InstagramVideoAdDetails