import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/modal";
import {HStack, ModalCloseButton, VStack} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import InputBox from "../../components/InputBox";
import {SubmitButton} from "formik-chakra-ui";
import * as Yup from "yup";

const validationSchema = Yup.object({
    report_url: Yup.string().url().required().label("Report_Url"),
    name: Yup.string().required().label("Name"),
});

function ReportDialog({open, handleClose, dialogTitle, buttonType, onSubmit, reportData}) {

    const [initialFormValue, setInitialFormValue] = useState(reportData)

    useEffect(() => {
        setInitialFormValue(reportData)
    }, [reportData]);

    return (
        <div>
            <Modal isOpen={open} onClose={handleClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{dialogTitle}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Formik
                            initialValues={initialFormValue}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                        >
                            {({values, errors}) => (
                                <VStack as={Form} w="full" align={"start"} spacing={4} paddingY={{base: "10", lg: 0}}>
                                    <HStack spacing={4} gap={4} w="full">
                                        <InputBox name="name" label="Report Name" borderColor="#A7A9BD" fontSize={14}
                                                  borderRadius={1} color={"#757998"}
                                                  fontWeight={600} />
                                    </HStack>
                                    <InputBox
                                        className='"profile-input"'
                                        name="report_url"
                                        label="Report URL"
                                        type="url"
                                        color={"#757998"}
                                        fontWeight={600}
                                        borderColor="#A7A9BD"
                                        fontSize={14}
                                        borderRadius={1}
                                    />
                                    <SubmitButton
                                        fontSize={14} fontFamily={"poppins"} px={10} py={5} bgColor={"#3B82F6"}
                                        borderRadius={1} style={{ margin: "1.4rem 0 1.4rem auto"}}
                                    >
                                        {buttonType}
                                    </SubmitButton>
                                </VStack>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}

export default ReportDialog;