import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    GridItem,
    FormControl,
    FormLabel,
    Select,
    Grid,
    Button,
    Box,
    RangeSlider,
    RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb, Image, Menu, MenuButton, MenuList, MenuItem, Checkbox,
} from '@chakra-ui/react'
import {Input} from "@chakra-ui/input";
import React, {useEffect, useState} from "react";
import {
    CampaignAgeGroup,
    CampaignInventoryRestriction,
    CampaignTargettingOptions, handleSubOptionsTargetting,
    TargettingOptionRelationshipGender,
    TargettingOptionRelationshipType,
} from "../../constant";
import '../../pages/AdCampaigns/adCampaign.css'
import {
    LocationOptions,
    TargettingLocationRadius
} from "../../pages/CampaignBriefs/constant/SelectValues";
import {settingDemographyDetails, settingMediaStrategies} from "../../store/adCampaign";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import MultiSelectInputBox from "../../components/MultiSelectInputBox";
import {Flex} from "@chakra-ui/layout";
import * as yup from "yup";
import {Form, Formik} from "formik";
import {useParams} from "react-router-dom";
import {SubmitButton} from "formik-chakra-ui";
import {ChevronDownIcon, CloseIcon} from "@chakra-ui/icons";

const initialValue = {
    gender: '',
    age: '',
    relationshipInterest: "",
    relationshipStatus: "",
    location: '',
    radius: null,
    geography: '',
    targettingOption1: "",
    targettingOption2: "",
    radiusValue: [1, 2]
}

const schema = yup.object().shape({
    gender: yup.string().required().label('Gender'),
    age: yup.string().required().label('Age'),
    relationshipStatus: yup.string().required().label('Relationship Status'),
    relationshipInterest: yup.string().required().label('Relationship Interest'),
    location: yup.string().required().label('Location'),
    targettingOption1: yup.string().required().label('Target Audience'),
});

function AdvancedSettingModal({onClose, isOpen}) {
    const [formData, setFormData] = useState(initialValue)
    const [showOptions1, setShowOptions1] = useState(false)
    const [showOptions2, setShowOptions2] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])
    const [subOptions1, setSubOptions1] = useState([])
    const [subOptions2, setSubOptions2] = useState([])
    const {demographicDetails} = useSelector((state) => state.adCampaign)
    const [selectedOption1, setSelectedOption1] = useState('')
    const [selectedOption2, setSelectedOption2] = useState('')

    useEffect(() => {
        if (!_.isEmpty(demographicDetails)) {
            setFormData(demographicDetails)
            if(!!demographicDetails.subOptions1.length && !!demographicDetails.subOptions2.length){
                setShowOptions2(true)
                setShowOptions1(true)
                handleSubOptionsTargetting(demographicDetails.targettingOption1, setOptions1)
                handleSubOptionsTargetting(demographicDetails.targettingOption2, setOptions2)
                setSubOptions1(demographicDetails.subOptions1)
                setSubOptions2(demographicDetails.subOptions2)

            }else{
                setShowOptions1(true)
                handleSubOptionsTargetting(demographicDetails.targettingOption1, setOptions1)
                setSubOptions1(demographicDetails.subOptions1)
            }
        }else{
            setFormData(initialValue)
        }
    }, [demographicDetails])


    useEffect(() => {
        if (selectedOption1) {
            setShowOptions1(true)
            handleSubOptionsTargetting(selectedOption1, setOptions1)
        }
        if (selectedOption2) {
            setShowOptions2(true)
            handleSubOptionsTargetting(selectedOption2, setOptions2)
        }
        if (subOptions1?.length > 0) {
            setShowMore(true)
        }
    }, [selectedOption1, selectedOption2, subOptions1])

    const dispatch = useDispatch()

    const genderOptions = ["Male", "Female", "Unknown"]

    function handleSubmitFormData(values) {
        dispatch(settingDemographyDetails({...values, subOptions1, subOptions2}))
        onClose()
    }

    function handleRemove(value) {
        if (value === 1) {
            setSelectedOption1("")
            setShowOptions1(false)
            setSubOptions1([])
            setSubOptions2([])
            setShowMore(false)
        }
        if (value === 2) {
            setSelectedOption2("")
            setSubOptions2([])
            setShowOptions2(false)
        }
    }

    function handleTargetAudience1(value){
        if(selectedOption1 !== value){
            setSubOptions1([])
        }
        setSelectedOption1(value)
    }

    function handleTargetAudience2(value){
        if(selectedOption2 !== value){
            setSubOptions2([])
        }
        setSelectedOption2(value)
    }

    function handleCheckedOption1(e){
        const { checked, value } = e.target;

        if (checked) {
            const newSelectedSafetyOptions = [...subOptions1, value];
            setSubOptions1(newSelectedSafetyOptions)
        } else {
            const filteredOptions = subOptions1.filter((e) => e !== value);
            setSubOptions1(filteredOptions)
        }
    }

    function handleCheckedOption2(e){
        const { checked, value } = e.target;

        if (checked) {
            const newSelectedSafetyOptions = [...subOptions2, value];
            setSubOptions2(newSelectedSafetyOptions)
        } else {
            const filteredOptions = subOptions2.filter((e) => e !== value);
            setSubOptions2(filteredOptions)
        }
    }

    function handleRemoveAudience1(e){
        e.stopPropagation()
        setSubOptions1([])
    }

    function handleRemoveAudience2(e){
        e.stopPropagation()
        setSubOptions2([])
    }

    return (
        <>
            <Modal onClose={onClose} isOpen={isOpen} isCentered size='5xl'>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Advanced Settings</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Formik initialValues={formData}
                                validationSchema={schema}
                                onSubmit={handleSubmitFormData}
                                enableReinitialize>
                            {({values, errors, setFieldValue, touched}) => (
                                <Box as={Form} noValidate>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/gender.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Gender</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="gender"
                                                    name="gender"
                                                    placeholder="Select Gender"
                                                    fontWeight={500}
                                                    variant="outline"
                                                    border="2px"
                                                    borderRadius={1}
                                                    borderColor="#E7E7E9"
                                                    value={values.gender}
                                                    onChange={(e) => setFieldValue("gender", e.target.value)}
                                                    isInvalid={errors.gender && touched.gender}
                                                >
                                                    {genderOptions.map((objective) => (
                                                        <option className='campaign-option' key={objective} value={objective}>
                                                            {objective}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.gender && touched.gender && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.gender}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem><GridItem>
                                        <FormControl isRequired>
                                            <Flex gap={1} >
                                                <Image
                                                    mt={1}
                                                    src={`${process.env.PUBLIC_URL}/images/age.svg`}
                                                    height="18px"
                                                />
                                                <FormLabel>Age</FormLabel>
                                            </Flex>
                                            <Select
                                                id="age"
                                                name="age"
                                                placeholder="Select Age"
                                                variant="outline"
                                                fontWeight={500}
                                                border="2px"
                                                borderRadius={1}
                                                borderColor="#E7E7E9"
                                                value={values.age}
                                                isInvalid={errors.age && touched.age}
                                                onChange={(e) => setFieldValue("age", e.target.value)}
                                            >
                                                {CampaignAgeGroup.map((objective) => (
                                                    <option className='campaign-option' key={objective.label} value={objective.label}>
                                                        {objective.label}
                                                    </option>
                                                ))}
                                            </Select>
                                            {errors.age && touched.age && (
                                                <Text color="red.500" fontSize="sm" mt={2}>
                                                    {errors.age}
                                                </Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/customer.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Target Audience</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="targettingOption1"
                                                    name="targettingOption1"
                                                    placeholder="Select"
                                                    variant="outline"
                                                    border="2px"
                                                    borderRadius={1}
                                                    fontWeight={500}
                                                    borderColor="#E7E7E9"
                                                    value={values.targettingOption1}
                                                    isInvalid={errors.targettingOption1 && touched.targettingOption1}
                                                    onChange={(e) => {setFieldValue("targettingOption1", e.target.value);
                                                        handleTargetAudience1(e.target.value)}}
                                                >
                                                    {CampaignTargettingOptions.map((objective) => (
                                                        <option className='campaign-option' key={objective.label} value={objective.label}>
                                                            {objective.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.targettingOption1 && touched.targettingOption1 && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.targettingOption1}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>{showOptions1 && <>
                                            <Flex justifyContent={'space-between'} >
                                                <FormLabel>{values.targettingOption1}</FormLabel>
                                                <Button colorScheme='red' rounded={999} size={'xs'}
                                                        onClick={() => {
                                                            handleRemove(1);
                                                            setFieldValue("targettingOption1", "")
                                                        }}>X</Button>
                                            </Flex>
                                            <Menu closeOnSelect={false}>
                                                <MenuButton borderRadius='md'
                                                            borderWidth='2px'
                                                            textAlign={'left'}
                                                            bg={''}
                                                            _hover={{ bg: '' }}
                                                            _expanded={{ bg: '' }}
                                                            _focus={{ boxShadow: 'outline' }} width={'full'} fontWeight={500} as={Button} rightIcon={<Flex alignItems={'center'} gap={2}>
                                                    <CloseIcon fontSize={12} onClick={handleRemoveAudience1}/>
                                                    <ChevronDownIcon fontSize={22}/>
                                                </Flex>}>
                                                    {!!subOptions1.length ? subOptions1.join(", ") : "Select"}
                                                </MenuButton>
                                                <MenuList minWidth={476} maxHeight={260} overflow={"auto"}>
                                                    {options1.map((item)=> (
                                                        <MenuItem fontWeight={500}><Checkbox isChecked={subOptions1.includes(item.value)} value={item.value} onChange={(e)=> handleCheckedOption1(e)}>{item.label}</Checkbox></MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                        </>}
                                        </GridItem>
                                        {showMore && <><GridItem>
                                            <FormControl>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/customer.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Target Audience</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="targettingOption2"
                                                    name="targettingOption2"
                                                    placeholder="Select"
                                                    variant="outline"
                                                    border="2px"
                                                    borderRadius={1}
                                                    fontWeight={500}
                                                    borderColor="#E7E7E9"
                                                    value={values.targettingOption2}
                                                    onChange={(e) => {
                                                        setFieldValue("targettingOption2", e.target.value);
                                                        handleTargetAudience2(e.target.value)
                                                    }}
                                                >
                                                    {CampaignTargettingOptions.map((objective) => (
                                                        <option className='campaign-option' key={objective.label} value={objective.label}>
                                                            {objective.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                            <GridItem>{showOptions2 && <>
                                                <Flex justifyContent={'space-between'} >
                                                    <FormLabel>{values.targettingOption2}</FormLabel>
                                                    <Button colorScheme='red' rounded={999} size={'xs'}
                                                            onClick={() => {
                                                                handleRemove(2);
                                                                setFieldValue("targettingOption2", "")
                                                            }}>X</Button>
                                                </Flex>
                                                <Menu closeOnSelect={false}>
                                                    <MenuButton borderRadius='md'
                                                                borderWidth='2px'
                                                                textAlign={'left'}
                                                                bg={''}
                                                                _hover={{ bg: '' }}
                                                                _expanded={{ bg: '' }}
                                                                _focus={{ boxShadow: 'outline' }} width={'full'} fontWeight={500} as={Button} rightIcon={<Flex alignItems={'center'} gap={2}>
                                                        <CloseIcon fontSize={12} onClick={handleRemoveAudience2}/>
                                                        <ChevronDownIcon fontSize={22}/>
                                                    </Flex>}>
                                                        {!!subOptions2.length ? subOptions2.join(", ") : "Select"}
                                                    </MenuButton>
                                                    <MenuList minWidth={476} maxHeight={260} overflow={"auto"}>
                                                        {options2.map((item)=> (
                                                            <MenuItem fontWeight={500}><Checkbox isChecked={subOptions2.includes(item.value)} value={item.value} onChange={(e)=> handleCheckedOption2(e)}>{item.label}</Checkbox></MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Menu>
                                            </>}
                                            </GridItem></>}
                                        <GridItem>
                                            <FormControl>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/like.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Relationship Interest</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="relationshipInterest"
                                                    name="relationshipInterest"
                                                    placeholder="Select"
                                                    variant="outline"
                                                    fontWeight={500}
                                                    border="2px"
                                                    borderRadius={1}
                                                    borderColor="#E7E7E9"
                                                    value={values.relationshipInterest}
                                                    isInvalid={errors.relationshipInterest && touched.relationshipInterest}
                                                    onChange={(e) => setFieldValue("relationshipInterest", e.target.value)}
                                                >
                                                    {TargettingOptionRelationshipGender.map((objective) => (
                                                        <option className='campaign-option' key={objective.label} value={objective.value}>
                                                            {objective.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.relationshipInterest && touched.relationshipInterest && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.relationshipInterest}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/people.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Relationship Status</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="relationshipStatus"
                                                    name="relationshipStatus"
                                                    placeholder="Select"
                                                    variant="outline"
                                                    fontWeight={500}
                                                    border="2px"
                                                    borderRadius={1}
                                                    borderColor="#E7E7E9"
                                                    value={values.relationshipStatus}
                                                    isInvalid={errors.relationshipStatus && touched.relationshipStatus}
                                                    onChange={(e) => setFieldValue("relationshipStatus", e.target.value)}
                                                >
                                                    {TargettingOptionRelationshipType.map((objective) => (
                                                        <option className='campaign-option' key={objective.label} value={objective.value}>
                                                            {objective.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.relationshipStatus && touched.relationshipStatus && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.relationshipStatus}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/planet-earth.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Geography</FormLabel>
                                                </Flex>
                                                <Input placeholder='Geography' value={values.geography} name='geography'
                                                       onChange={(e) => setFieldValue("geography", e.target.value)}/>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <Flex gap={1} >
                                                    <Image
                                                        mt={1}
                                                        src={`${process.env.PUBLIC_URL}/images/map.svg`}
                                                        height="18px"
                                                    />
                                                    <FormLabel>Location</FormLabel>
                                                </Flex>
                                                <Select
                                                    id="location"
                                                    name="location"
                                                    placeholder="Select Location"
                                                    variant="outline"
                                                    border="2px"
                                                    borderRadius={1}
                                                    fontWeight={500}
                                                    borderColor="#E7E7E9"
                                                    value={values.location}
                                                    isInvalid={errors.location && touched.location}
                                                    onChange={(e) => setFieldValue("location", e.target.value)}
                                                >
                                                    {LocationOptions.map((objective) => (
                                                        <option className='campaign-option' key={objective.label} value={objective.value}>
                                                            {objective.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {errors.location && touched.location && (
                                                    <Text color="red.500" fontSize="sm" mt={2}>
                                                        {errors.location}
                                                    </Text>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem colSpan={2}>
                                            <Flex gap={1} >
                                                <Image
                                                    mt={1}
                                                    src={`${process.env.PUBLIC_URL}/images/radius.svg`}
                                                    height="18px"
                                                />
                                                <FormLabel>Radius</FormLabel>
                                            </Flex>
                                            <Box className="household-income-box">
                                                <Box className="income-range">
                                                    <RangeSlider
                                                        defaultValue={values.radiusValue}
                                                        min={0}
                                                        max={6}
                                                        step={1}
                                                        onChangeEnd={(val) => {
                                                            const start = val[0];
                                                            const end = val[1];
                                                            setFieldValue(
                                                                "radius",
                                                                TargettingLocationRadius.slice(start, end + 1),
                                                            )
                                                            setFieldValue("radiusValue", val)
                                                        }}
                                                    >
                                                        <RangeSliderTrack>
                                                            <RangeSliderFilledTrack/>
                                                        </RangeSliderTrack>
                                                        <RangeSliderThumb index={0}/>
                                                        <RangeSliderThumb index={1}/>
                                                    </RangeSlider>
                                                    <Box className="range-text">
                                                        {TargettingLocationRadius.map((el, index) => (
                                                            <Text key={index}>{el?.label}</Text>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <SubmitButton
                                        display={'flex'} justifyContent={'flex-end'}
                                        fontFamily={"poppins"} px={8} py={5} _hover={{bg: ''}}
                                        color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"} borderColor="#1F95FF"
                                        style={{margin: "1.4rem 0 1.4rem auto"}}
                                    >
                                        Save
                                    </SubmitButton>
                                </Box>
                            )}
                        </Formik>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default AdvancedSettingModal