import { Grid, GridItem, Heading, VStack, Box, Text } from "@chakra-ui/react";
import {useEffect, useState} from "react";
import { useRecoilValue } from "recoil";
import { profile } from "../../atoms/authAtom";
import { useGetDashboardComments } from "../../hooks/dashboard/useGetDashboardComments";
import { Comments } from "./components/Comments";
import { useSelector } from "react-redux";
import {useGetClients} from "../../hooks/dashboard/useGetClients";
import {useGetClientDetails} from "../../hooks/clients/useGetClientDetails";

export const Greeting = () => {
    let myDate = new Date();
    let hours= myDate.getHours();
    let greet;

    if (hours < 12)
        greet =  "morning";
    else if (hours >= 12 && hours <= 17)
        greet = "afternoon";
    else if (hours >= 17 && hours <= 24)
        greet = "evening";

    return <span>Good {greet}</span>
}

const Dashboard = () => {
    const { name } = useRecoilValue(profile);
    const [commentDashboardData, setCommentDashboardData] = useState({
        comments: [], clientData: []
    })
    const clientId = useSelector((state) => state.client.clientId);

    useEffect(() => {
        mutate();
    }, [clientId]);

    const { mutate, data } = useGetDashboardComments();

    useEffect(() => {
        if(data) {
            setCommentDashboardData(data)
        }
    }, [data]);

    const { clientData, comments } = commentDashboardData

    if(comments.length > 0) {
        for(let i = 0; i < comments?.length; i++) {
            comments[i].clientData = clientData[i]
        }
    }

    return (
        <Grid templateColumns="repeat(12, 1fr)" gap={4} padding={{ base: "0" }}>
            <GridItem colSpan={{ base: "12", lg: "8" }}>
                <VStack align="stretch">
                    <Heading color="#757998" fontSize={"30px"} fontFamily={"Lora"} fontWeight="500">
                        <Greeting />, {name}.
                    </Heading>
                    <Text fontSize={"16px"} fontWeight={"600"} color={"#3B82F6"}> Here’s what you missed:  </Text>
                </VStack>
            </GridItem>
            <GridItem colSpan={{base: "12", lg:"8"}} mt={5} paddingRight={{ base: 0, lg: "100px" }}>
                <Comments data={comments}/>
            </GridItem>
            <GridItem colSpan={{base: "12", lg:"4"}} mt={5}>
                <Box border={"2px solid rgba(167, 169, 189, 0.2)"} borderRadius={"10px"}  height={"400px"} padding={"30px 60px"}>
                    <Text color={"#79A9FF"} fontWeight={"bold"} marginBottom={"20px"} >What's new?</Text>
                    <Box color={"#757998"}>
                   <Text>September 19<sup>th</sup> 2023 </Text>
                    <ul style={{ paddingLeft: "40px" }}>
                        <li>v12.1 update</li>
                        <li> Implemented a minimized sidebar.</li>
                         <li> Frontend modifications</li>
                        <li><a href="https://docs.google.com/document/d/1IB8lMVpmknd2e7sJ1LaBleMFPo1JjOdVvwlTQvWapH0/edit#heading=h.lluk4dipbjf5" target="_blank"><u>Read more...</u></a></li>
                    </ul>
                    <Text>September 15<sup>th</sup> 2023 </Text>
                    <ul style={{ paddingLeft: "40px" }}>
                        <li>v12.0 update</li>
                        <li>Looker Studio is integrated with the Kedet</li>
                        <li><a href="https://docs.google.com/document/d/1IB8lMVpmknd2e7sJ1LaBleMFPo1JjOdVvwlTQvWapH0/edit#heading=h.cl6savrhh17t" target="_blank"><u>Read more...</u></a></li>
                    </ul>
                    
                    </Box>
                </Box>
            </GridItem>
        </Grid>
    );
};

export default Dashboard;
