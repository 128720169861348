import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Heading
} from "@chakra-ui/react";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import {
    dv360AdUploadalueLengths,
    dv360AdUploadInitialValues,
    dv360AdVideoInitialValue, tiktokAdUploadValueLengths
} from "../../../../../constant/InitialValues";
import {CreativeType, hostingSource} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {settingEditMediaType, settingFormDetails, settingFormMedia} from "../../../../../../../store/adUploads";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {settingTiktokVideo, settingTiktokVideoFormDetail, settingTiktokVideoFormMedia} from "./store";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
    adName: "",
    displayName: "",
    description:""
}

function TiktokVideoAdDetails({activeStep, steps, prevStep, nextStep, label}) {
    const {id, adUploadId} = useParams();

    const clientId = useSelector((state) => state.client.clientId);
    const { formDetails } = useSelector((state) => state.tiktokVideoAd);

    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if(adUploadId) {
            dispatch(settingTiktokVideoFormDetail({...data?.adUpload?.detail, adName: data?.adUpload?.detail?.name, description: data?.adUpload?.detail?.description, displayName:data?.adUpload?.detail?.displayName}))
            dispatch(settingTiktokVideoFormMedia({ fileURL: data?.adUpload?.detail?.audio?.fileInfoList }))
        }

    }, [data])

    const dispatch = useDispatch();

    const schema = yup.object().shape({
        adName: yup.string().trim().min(4).max(40).required().matches(/^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,"Emojis are not allowed as ad Name").label('Ad name'),
        displayName: yup.string().trim().min(2).max(20).required().matches(/^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,"Emojis are not allowed as display name").label('Display name'),
        description: yup.string().trim().min(1).max(100).required().matches( /^[!@$%^&()-=+;:,<.>0-9a-zA-Z\s]+$/i,"Emojis and Special characters consists {} and # is not allowed in the description").label('Description'),
    });


    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(settingTiktokVideoFormDetail({ fileInfoList: data?.adUpload?.detail?.audio?.fileInfoList, ...data?.adUpload?.detail,...values}))
            nextStep()
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue('adName', formDetails.adName || "")
        setFieldValue('displayName', formDetails.displayName || "")
        setFieldValue('description', formDetails.description || "")
    }, [formDetails])


    return (
        <>
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review your ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            3 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please fill in your ad details.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            3 steps to complete
                        </Heading>
                    </Box>
                )
            }


            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        gap={4}
                        className="fb-upload"
                    >
                        <GridItem
                            w="full"
                            colSpan={{base: 6, lg: 4}}
                        >
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="adName" color="gray" fontSize="sm" display={"none"}>
                                        Ad Name - max 512 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="adName"
                                            name="adName"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder:"Ad Name - max 512 characters"
                                            }}
                                            onChange={(e)=>setFieldValue('adName',e.target.value)}
                                        />
                                        <TooltipComponent text={"The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."} />
                                        <CircularProgress
                                            max={tiktokAdUploadValueLengths.adName}
                                            value={values?.adName?.length}
                                            color={
                                                values?.adName?.length > tiktokAdUploadValueLengths?.adName
                                                    ? "red.400"
                                                    : "green.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values?.adName?.length >
                                                tiktokAdUploadValueLengths?.adName
                                                    ? tiktokAdUploadValueLengths?.adName -
                                                    values?.adName?.length
                                                    : values?.adName?.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="displayName" color="gray" fontSize="sm" display={"none"}>
                                        Display Name - max 512 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="displayName"
                                            name="displayName"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder:"Display Name - max 512 characters"
                                            }}
                                            onChange={(e)=>setFieldValue('displayName',e.target.value)}
                                        />
                                        <CircularProgress
                                            max={tiktokAdUploadValueLengths.displayName}
                                            value={values?.displayName?.length}
                                            color={
                                                values?.displayName?.length > tiktokAdUploadValueLengths?.displayName
                                                    ? "red.400"
                                                    : "green.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values?.displayName?.length >
                                                tiktokAdUploadValueLengths?.displayName
                                                    ? tiktokAdUploadValueLengths?.displayName -
                                                    values?.displayName?.length
                                                    : values?.displayName?.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="description"
                                        color="gray"
                                        fontSize="sm"
                                        display={"none"}
                                    >
                                        Description
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl

                                            id="description"
                                            name="description"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder:"Description"
                                            }}
                                            onChange={(e)=>setFieldValue('description',e.target.value)}
                                        />
                                        <CircularProgress
                                            max={tiktokAdUploadValueLengths?.description}
                                            value={values?.description?.length}
                                            color={
                                                values?.description?.length > tiktokAdUploadValueLengths?.description
                                                    ? "red.400"
                                                    : "green.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values?.description?.length >
                                                tiktokAdUploadValueLengths?.description
                                                    ? tiktokAdUploadValueLengths?.description -
                                                    values?.description?.length
                                                    : values?.description?.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                        </GridItem>
                    </Grid>
                    <Flex width="100%" justify="flex-end" py={10}>
                        {
                            !adUploadId &&  <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                        }
                        {
                            label === "Ad Details" && !(activeStep === steps.length - 1) && (
                                <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                    Next
                                </Button>
                            )
                        }
                    </Flex>
                </Form>
            </FormikProvider>

        </>
    )
}

export default TiktokVideoAdDetails