import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, HStack,
    Icon, Text, useDisclosure,
} from "@chakra-ui/react";
import {Form, FormikProvider, useFormik} from "formik";
import {InputControl, SelectControl} from "formik-chakra-ui";
import _ from "lodash";
import React, {useEffect, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../components/PopupModal/SuccessModal";
import {
    AD_UPLOAD_STATUS_COLOR,
    AD_UPLOAD_TYPE, INSTAGRAM_IMAGE_HEIGHT, INSTAGRAM_IMAGE_WIDTH
} from "../../../../../constant";
import instance from "../../../../../helpers/axios";
import {useUpdateAdUploadStatus} from "../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {useUploadImage} from "../../../../../hooks/campaign-briefs/useUploadImage";
import {useCreateAdPreview} from "../../../../../hooks/campaign-briefs/useCreateAdPreview";
import WithAppContext from "../../../../../helpers/appContext";
import {
    dv360AdUploadalueLengths,
    googleCSVInitialValue, googleCsvLengths,
    instagramImageAdInitialValue
} from "../../../constant/InitialValues";
import {useGlobalContext} from "./store/WrapContext";
import {useGetAdUpload} from "../../../../../hooks/campaign-briefs/useGetAdUpload";

let SUPPORTED_FORMATS = ['text/csv']

const GoogleCSVAdDetails = (props) => {
    const {
        additioanData,
        data,
        setTabIndex,
        tabIndex,
        handlePreviewData,
        url,
        method,
        campaignId,
        context
    } = props;

    const {id, googleId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [link, setlink] = useState("");
    const {mutateAsync} = useUploadImage();
    const {mutate} = useUpdateAdUploadStatus();
    const {mutateAsync: mutateAsyncPreview} = useCreateAdPreview();
    const {setAppLoading, SuccessToaster, ErrorToaster} = context

    const {data: formData, refetch} = useGetAdUpload(clientId, id, googleId);

    useEffect(() => {
        if(googleId) {
            refetch();
        }
    }, []);


    let schema = yup.object().shape({
        adName: yup.string().required("Ad Name is require"),
        siteLink: yup.mixed().required('Sitelink file is required').test('fileFormat', 'CSV file only', (value) => {
            return value && ['text/csv'].includes(value.type);
        }),
        extension: yup.mixed().required('Extension file is required').test('fileFormat', 'CSV file only', (value) => {
            return value && ['text/csv'].includes(value.type);
        }),
        master: yup.mixed().required('Master file is required').test('fileFormat', 'CSV file only', (value) => {
            return value && ['text/csv'].includes(value.type);
        }),
    });

    // text/csv

    useEffect(() => {
        if (formData && formData?.adUpload?.id) {
            const {name} = data;
            setFieldValue("adName", name);
            delete schema.fields.siteLink
            delete schema.fields.extension
            delete schema.fields.master
        }
    }, [formData]);

    const formik = useFormik({
        initialValues: googleCSVInitialValue,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                setAppLoading(true);
                const formData = new FormData();
                formData.append('name', values.adName);
                formData.append('siteLink', values.siteLink);
                formData.append('extension', values.extension);
                formData.append('master', values.master);
                formData.append('ad_upload_type', AD_UPLOAD_TYPE.GOOGLE_CSV)
                // if (!data?.id) {
                //     payload = {
                //         ...payload,
                //         ad_upload_type: AD_UPLOAD_TYPE.INSTAGRAM_IMAGE,
                //     };
                // }
                await instance({
                    method: method,
                    url: url,
                    withCredentials: false,
                    data: formData,
                })
                    .then((res) => {
                        if (res.status === 200) setSuccessModal(true);
                        setAppLoading(false);
                    })
                    .catch((error) => {
                        setlink(error.response.data.message);
                        setErrorModal(true);
                        setAppLoading(false);
                    });
            } catch (error) {
                setAppLoading(false);
            }
        },
    });

    const {
        errors,
        values,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
    } = formik;

    const adUploadStatusColor = (item) => {
        return item ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)] : AD_UPLOAD_STATUS_COLOR["OTHER"];
    }

    const handleStatus = (status) => {
        mutate(
            {
                clientId,
                campaignId,
                adUploadId: data?.id,
                status,
            },
            {
                onSuccess: () => {
                    navigate(`/campaign-briefs/${campaignId}`, {
                        state: {isShowUpload: true},
                    });
                },
            }
        )
    }

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        navigate(`/campaign-briefs/${campaignId}`, {
            state: {isShowUpload: true},
        });
    }

    async function handleUpdate() {
        try {
            setAppLoading(true);
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: {
                    name: values.adName
                },
            })
                .then((res) => {
                    if (res.status === 200) setSuccessModal(true);
                    setAppLoading(false);
                })
                .catch((error) => {
                    setlink(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false);
                });
        } catch (error) {
            setAppLoading(false);
        }
    }

    function sendSiteLink(data) {
    }

    function sendExtension(data) {
    }

    function sendMaster(data) {
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" my={4} mb={10}>
                <Heading color={"gray"} fontSize="xl">
                    Current status:
                    <span style={{marginLeft: "10px"}}>
            <Icon
                viewBox="0 0 200 200"
                mr={2}
                color={adUploadStatusColor(data && data.status)}
            >
              <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
                        {data && data.status || "Draft"}
          </span>
                </Heading>

                {data && _.toUpper(data.status) === "CREATED" &&
                    <Box display="flex">
                        <Button
                            size="sm"
                            mr={2}
                            colorScheme="green"
                            backgroundColor="green.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Approved')}
                        >
                            Approve
                        </Button>
                        <Button
                            size="sm"
                            colorScheme="red"
                            backgroundColor="red.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Rejected')}
                        >
                            Reject
                        </Button>
                    </Box>
                }
            </Box>
            <Grid className="fb-upload-detail-form">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel htmlFor="adName" color="gray" fontSize="sm">
                                Ad Name - max 512 characters
                            </FormLabel>
                            <div className="input-box">
                                <InputControl
                                    id="adName"
                                    name="adName"
                                    placeholder=""
                                    style={{width: "50%"}}
                                    inputprops={{
                                        variant: "outline",
                                        type: "text",
                                    }}
                                    onBlur={handleBlur}
                                />
                                <CircularProgress
                                    max={googleCsvLengths.adName}
                                    value={values.adName.length}
                                    color={
                                        values.adName.length > googleCsvLengths.adName
                                            ? "red.400"
                                            : "green.400"
                                    }
                                >
                                    <CircularProgressLabel>
                                        {values.adName.length >
                                        googleCsvLengths.adName
                                            ? googleCsvLengths.adName -
                                            values.adName.length
                                            : values.adName.length}
                                    </CircularProgressLabel>
                                </CircularProgress>
                            </div>
                        </FormControl>
                        {
                            !googleId && (
                                <>
                                    <FormControl>
                                        <FormLabel htmlFor="adName" color="gray" fontSize="sm">
                                            Site Link
                                        </FormLabel>
                                        <Box mb={1}>
                                            <input className={errors.siteLink && touched.siteLink ? 'error' : null}
                                                   type="file" name="siteLink" accept=".csv" onChange={(event) => {
                                                setFieldValue("siteLink", event.currentTarget.files[0]);
                                                sendSiteLink(event.currentTarget.files[0])
                                            }}/>
                                        </Box>
                                        {errors.siteLink && touched.siteLink ?
                                            <Text color="red.500">{errors.siteLink}</Text> : null}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="adName" color="gray" fontSize="sm">
                                            Extension
                                        </FormLabel>
                                        <Box mb={1}>
                                            <input className={errors.extension && touched.extension ? 'error' : null}
                                                   type="file" name="extension" accept=".csv" onChange={(event) => {
                                                setFieldValue("extension", event.currentTarget.files[0]);
                                                sendExtension(event.currentTarget.files[0])
                                            }}/>
                                        </Box>
                                        {errors.extension && touched.extension ?
                                            <Text color="red.500">{errors.extension}</Text> : null}
                                    </FormControl>
                                    <FormControl mb={5}>
                                        <FormLabel htmlFor="adName" color="gray" fontSize="sm">
                                            Master
                                        </FormLabel>
                                        <Box mb={1}>
                                            <input className={errors.master && touched.master ? 'error' : null} type="file"
                                                   name="master" accept=".csv" onChange={(event) => {
                                                setFieldValue("master", event.currentTarget.files[0]);
                                                sendMaster(event.currentTarget.files[0])
                                            }}/>
                                        </Box>
                                        {errors.master && touched.master ?
                                            <Text color="red.500">{errors.master}</Text> : null}
                                    </FormControl>
                                </>

                            )
                        }

                        {
                            data?.id ? <Button
                                size="small"
                                css={css({
                                    background: "#4CAF50 !important",
                                    borderRadius: "32px",
                                    width: "134px",
                                    height: "33px",
                                })}
                                onClick={handleUpdate}
                            >
                                Update Ad
                            </Button> : (
                                <Button
                                    size="small"
                                    css={css({
                                        background: "#4CAF50 !important",
                                        borderRadius: "32px",
                                        width: "134px",
                                        height: "33px",
                                    })}
                                    type="submit"
                                    // disabled={!isValid}
                                >
                                    Submit Ad
                                    {/*{data?.id ? "Update Ad" : "Submit Ad"}*/}
                                </Button>
                            )
                        }

                    </Form>
                </FormikProvider>
            </Grid>

            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={link}
            />
        </>
    );
};

export default WithAppContext(GoogleCSVAdDetails)
