import React, {useEffect, useState} from 'react';
import {Flex, Heading} from "@chakra-ui/layout";
import {
    Box,
    Text,
    Image, SimpleGrid, Button, Divider
} from "@chakra-ui/react";
import {settingCampaignObjective} from "../../store/adCampaign";
import {useDispatch, useSelector} from "react-redux";
import {awarenessType, considerationType, conversionType} from "../../constant";
import {CheckIcon} from "@chakra-ui/icons";

function CampaignObjective({activeStep, nextStep, prevStep, handleNotes}) {
    const {objective} = useSelector((state) => state.adCampaign)

    const dispatch = useDispatch();

    const activeTab = {
        border: "2px solid #1F95FF",
        boxSizing: "border-box"
    }

    function handleClick(value) {
        dispatch(settingCampaignObjective(value))
    }

    return (
        <div style={{fontFamily:"poppins"}}>
            <Heading mt={5} fontSize="3xl" fontFamily={"poppins"}>Campaign Objective</Heading>
            <Box height={460} color={"#363849"} >
                <Text fontSize='lg' fontWeight={700}  mt={10} mb={2}>Awareness</Text>
                <SimpleGrid columns={[1, 1, 1, 3]} spacing='20px'>
                    {
                        awarenessType.map((item, index) => {
                            const {image, title, desc, value} = item;
                            return (
                                <Box
                                    cursor={"pointer"}
                                    border={"2px solid #E7E7E9"}
                                    borderRadius={"10px"}
                                    height='95px'
                                    display={"flex"}
                                    p={4}
                                    key={title}
                                    style={objective === value ? activeTab : null}
                                    onClick={() => handleClick(value)}
                                >
                                    <Flex alignItems={'center'} >
                                        <Box display={"flex"} alignItems={"center"} gap={"14px"} color={"#757998"} width={"80%"}>
                                            <Box backgroundColor={'gray.100'} padding={4} borderRadius={999}>
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/images/${image}`}
                                                    height="28px"
                                                    width="40px"
                                                />
                                            </Box>
                                            <Flex direction='column' gap={1}>
                                                <Heading fontSize='md' fontFamily={"poppins"} fontWeight={600} color={"#363849"}>{title}</Heading>
                                                <Text lineHeight='1.1rem' fontSize={15} color={"#848484"}>{desc}</Text>
                                            </Flex>
                                        </Box>
                                        {objective === value && <CheckIcon width={"20%"} marginRight={2} fontSize={"lg"} color={'#1f95ff'}/>}
                                    </Flex>
                                </Box>
                            )
                        })
                    }
                </SimpleGrid>

                <Text fontSize='lg' fontWeight={700} mt={8} mb={2}>Consideration</Text>
                <SimpleGrid columns={[1, 1, 1, 3]} spacing='20px'>
                    {
                        considerationType.map((item, index) => {
                            const {image, title, desc, value} = item;
                            return (
                                <Box
                                    cursor={"pointer"}
                                    border={"2px solid #E7E7E9"}
                                    borderRadius={"10px"}
                                    height='95px'
                                    display={"flex"}
                                    p={4}
                                    key={title}
                                    style={objective === value ? activeTab : null}
                                    onClick={() => handleClick(value)}
                                >
                                    <Flex alignItems={'center'} >
                                        <Box display={"flex"} alignItems={"center"} gap={"14px"} color={"#757998"} width={"80%"}>
                                            <Box backgroundColor={'gray.100'} padding={4} borderRadius={999}>
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/images/${image}`}
                                                    height="28px"
                                                    width="40px"
                                                />
                                            </Box>
                                            <Flex direction='column' gap={1}>
                                                <Heading fontSize='md' fontFamily={"poppins"} fontWeight={600} color={"#363849"}>{title}</Heading>
                                                <Text lineHeight='1.1rem' fontSize={15} color={"#848484"}>{desc}</Text>
                                            </Flex>
                                        </Box>
                                        {objective === value && <CheckIcon width={"20%"} marginRight={2} fontSize={"lg"} color={'#1f95ff'}/>}
                                    </Flex>
                                </Box>
                            )
                        })
                    }
                </SimpleGrid>

                <Text fontSize='lg' fontWeight={700} mt={8} mb={2}>Conversion</Text>
                <SimpleGrid columns={[1, 1, 1, 3]} spacing='20px'>
                    {
                        conversionType.map((item, index) => {
                            const {image, title, desc, value} = item;
                            return (
                                <Box
                                    cursor={"pointer"}
                                    border={"2px solid #E7E7E9"}
                                    borderRadius={"10px"}
                                    height='95px'
                                    display={"flex"}
                                    p={4}
                                    key={title}
                                    style={objective === value ? activeTab : null}
                                    onClick={() => handleClick(value)}
                                >
                                    <Flex alignItems={'center'} >
                                        <Box display={"flex"} alignItems={"center"} gap={"14px"} color={"#757998"} width={"80%"}>
                                            <Box backgroundColor={'gray.100'} padding={4} borderRadius={999}>
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/images/${image}`}
                                                    height="28px"
                                                    width="40px"
                                                />
                                            </Box>
                                            <Flex direction='column' gap={1}>
                                                <Heading fontSize='md' fontFamily={"poppins"} fontWeight={500} color={"#363849"}>{title}</Heading>
                                                <Text lineHeight='1.1rem' fontSize={15} color={"#848484"}>{desc}</Text>
                                            </Flex>
                                        </Box>
                                        {objective === value && <CheckIcon width={"20%"} marginRight={2} fontSize={"lg"} color={'#1f95ff'}/>}
                                    </Flex>
                                </Box>
                            )
                        })
                    }
                </SimpleGrid>
            </Box>
            <Divider my={8}/>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button fontWeight={400} fontFamily={"poppins"} leftIcon={<Image
                    src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                    height="18px"
                />} onClick={handleNotes}>Add Notes</Button>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button mr={4} _hover={{ bg: '' }} color={"#1F95FF"} bgColor={"#FFFFFF"} border={"2px"} borderColor="#1F95FF" py={5} px={8} onClick={() => prevStep()}>Back</Button>
                    <Button type='submit' _hover={{ bg: '' }} py={5} color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"} borderColor="#1F95FF"  px={8} isDisabled={!objective} onClick={() => nextStep()}>Next</Button>
                </div>
            </div>
        </div>
    );
}

export default CampaignObjective;