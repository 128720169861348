import { v4 as uuidv4 } from "uuid";
import {
    FiGrid,
    FiSettings,
    FiUser,
    FiCast,
    FiUsers,
    FiServer,FiHome
} from "react-icons/fi";
import { useColorModeValue } from "@chakra-ui/react";

export const TEXT_COLOR = () => useColorModeValue("gray.600", "gray.50");
export const GREY_TEXT_COLOR = "#A7A9BD";
export const BLUE_BUTTON_COLOR = "#3B82F6";
export const PREVIEW_BG_COLOR = "rgba(227, 237, 255, 0.5)"
export const INDICATOR_BG_COLOR = "#79A9FF";

export const MenuList = [
    {
        id: uuidv4(),
        name: "Home",
        path: "/",
        icon: FiHome,
    },{
        id: uuidv4(),
        name: "Dashboard",
        path: "/reports",
        icon: FiGrid,
    },
    {
        id: uuidv4(),
        name: "Ad Campaign",
        path: "/campaign-briefs",
        icon: FiCast,
    },
    {
        id: uuidv4(),
        name: "Users",
        path: "/users",
        icon: FiUser,
    },
    {
        id: uuidv4(),
        name: "Clients",
        path: "/clients",
        icon: FiUsers,
    },
    //   {
    //     id: uuidv4(),
    //     name: 'Config management',
    //     path: '/config',
    //     icon: FiServer,
    //   },
    {
        id: uuidv4(),
        name: "Settings",
        path: "/settings",
        icon: FiSettings,
    },
    {
        id:uuidv4,
        name: 'Looker Studio Config',
        path:"/looker-studio-config",
        icon: FiSettings
    }
];

export const UserMenuList = [
    {
        id: uuidv4(),
        name: "Home",
        path: "/",
        icon: FiHome,
    },
    {
        id: uuidv4(),
        name: "Dashboard",
        path: "/reports",
        icon: FiGrid,
    },
    {
        id: uuidv4(),
        name: "Ad Campaign",
        path: "/campaign-briefs",
        icon: FiCast,
    },
    // {
    //     id: uuidv4(),
    //     name: "Clients",
    //     path: "/clients",
    //     icon: FiUsers,
    // },
    {
        id: uuidv4(),
        name: "Settings",
        path: "/settings",
        icon: FiSettings,
    },
    {
        id:uuidv4,
        name: 'Looker Studio Config',
        path:"/looker-studio-config",
        icon: FiSettings
    }
];

export const ClientMenuList = [
    // {
    //     id: uuidv4(),
    //     name: "Dashboard",
    //     path: "/",
    //     icon: FiGrid,
    // },
    {
        id: uuidv4(),
        name: "Dashboard",
        path: "/reports",
        icon: FiGrid,
    },
    {
        id: uuidv4(),
        name: "Ad Campaign",
        path: "/campaign-briefs",
        icon: FiCast,
    },
    // {
    //     id: uuidv4(),
    //     name: "Clients",
    //     path: "/clients",
    //     icon: FiUsers,
    // },
    {
        id: uuidv4(),
        name: "Settings",
        path: "/settings",
        icon: FiSettings,
    },
];

export const DeveloperMenuList = [
    {
        id: uuidv4(),
        name: "Home",
        path: "/",
        icon: FiHome,
    },
    {
        id: uuidv4(),
        name: "Dashboard",
        path: "/reports",
        icon: FiGrid,
    },
    {
        id: uuidv4(),
        name: "Ad Campaign",
        path: "/campaign-briefs",
        icon: FiCast,
    },
    {
        id: uuidv4(),
        name: "Clients",
        path: "/clients",
        icon: FiUsers,
    },
    {
        id: uuidv4(),
        name: "Users",
        path: "/users",
        icon: FiUser,
    },
    {
        id: uuidv4(),
        name: "Config management",
        path: "/config",
        icon: FiServer,
    },
    {
        id: uuidv4(),
        name: "Spreadsheet Config",
        path: "/spreadsheet-config",
        icon: FiServer,
    },
    {
        id: uuidv4(),
        name: "Settings",
        path: "/settings",
        icon: FiSettings,
    },
    {
        id:uuidv4,
        name: 'Looker Studio Config',
        path:"/looker-studio-config",
        icon: FiSettings
    }
];
