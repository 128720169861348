import { HStack, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

const NavItem = ({ icon, title, path, ...rest }) => {
  const COLOR = useColorModeValue('#757998', '#757998')
  const BG_COLOR = useColorModeValue('#EFF6FF80', '#EFF6FF80')

  return (
    <Link
      as={NavLink}
      to={path}
      textDecoration="none"
      width="full"
      _focus={{ boxShadow: ' none' }}
      _hover={{
        textDecoration: 'none',
      }}
      {...rest}
    >
      {({ isActive }) => (
        <HStack
          spacing="4"
          borderRight={isActive ? '2px' : 'none'}
          bg={isActive ? BG_COLOR : 'transparent'}
          borderColor={isActive ? '#79A9FF' : 'transparent'}
          color={isActive ? '#79A9FF' : COLOR}
          _hover={{
            color: '#79A9FF',
          }}
          pl="4"
          py={2}
        >
          {icon && <Icon fontSize="18px" as={icon} />}
          <Text margin={0} fontWeight="medium" fontSize="14px">
            {title}
          </Text>
        </HStack>
      )}
    </Link>
  )
}

export default NavItem
