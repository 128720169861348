import {
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
    Box,
    Flex,
} from '@chakra-ui/react'
import {For} from 'react-haiku'
import DetailsTab from './DetailsTab'
import NotificationUpdate from './NotificationUpdate'
import PasswordUpdate from './PasswordUpdate'
import {useState} from "react";

const Settings = () => {
    const [tabIndex, setTabIndex] = useState(0)
    return (
        <Flex>
            <Box className="create-campaign-main">
                <VStack alignItems="stretch" spacing={6}>
                    <Heading color="#757998" fontWeight="600" fontSize={30}>
                        Profile
                    </Heading>
                    <Tabs w="full" color={"#A7A9BD"} fontWeight={600} size="sm" fontSize={10} onChange={(index) => setTabIndex(index)}>
                        <TabList>
                            <Tab>Personal Details</Tab>
                            <Tab>Password</Tab>
                            <Tab>Notifications</Tab>
                        </TabList>
                        <TabPanels p='2rem'>
                            <TabPanel><DetailsTab/></TabPanel>
                            <TabPanel> <PasswordUpdate/></TabPanel>
                            <TabPanel> <NotificationUpdate/></TabPanel>
                        </TabPanels>
                    </Tabs>
                </VStack>
            </Box>
            <Box width={"22%"}></Box>
        </Flex>
    )
}

export default Settings
