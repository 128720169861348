import {
    Box,
    Button,
    Center,
    css,
    Flex,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    VStack,
    ChakraProvider, extendTheme
} from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import React, {useState, useEffect} from "react";
import {For} from "react-haiku";
import {CAMPAIGN_BRIEFS_TABS} from "../../../constant";
import {useGetClientDetailsOnClick} from "../../../hooks/clients/useGetClientDetails";
import CreateForm from "./CampaignDetails/CreateForm";
import {BiPlusCircle, BiArrowBack} from "react-icons/bi";
import {TEXT_COLOR} from "../../../layout/constant/MenuList";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import CreateFacebookCampaign from "./FbCampaigns/CreateFacebookCampaignModel";
import CreateDV360Campaign from "./Dv360Campaings/CreateDV360CampaignModel";
import AdUploadList from "./AdUpload/AdUploadList";
import {Comment} from "./Comment";
import {profile} from "../../../atoms/authAtom";
import {useDispatch, useSelector} from "react-redux";
import FbCampaignList from "./FbCampaigns/FbCampaignList";
import DV360CampaignList from "./Dv360Campaings/Dv360CampaignList";
import FbAdSetsList from "./FbAdSets/FbAdSetsList";
import AuditLogsList from "./AuditLogsList";
import {useGetCampaign} from "../../../hooks/campaign-briefs/useGetCampaign";
import {useUpdateCampaign} from "../../../hooks/campaign-briefs/useUpdateCampaign";
import {updateCampaignFbObjective} from "../../../store/campaign/campaignThunk";
import {ROLES} from "../../../constant";
import CreateFormStepByStep from "./CampaignDetailsStepByStep/CreateFormStepByStep";
import "./CampaignDetailsStepByStep/FormStepButton";
import StepByStepAdUpload from "./AdUpload/StepByStepAdUpload";
import SelectAdType from "./AdUpload/SelectAdType";
import {ContextWrapper} from "../ContextStore/ContextWrapper";
import VectorIcon from "../../../assets/images/Vector.png"
import { Image } from '@chakra-ui/react'
import InputBox from "../../../components/InputBox";

const theme = extendTheme({
    components: {
        Steps,
    },
});

const NewCreateCampaign = () => {
    const [tabIndex, SetTabIndex] = useState(4);
    const [isRemoveTabs, SetIsRemoveTabs] = useState(null);
    const dispatch = useDispatch();
    const {
        isOpen: isFacebookModelOpen,
        onOpen: onFacebookModelOpen,
        onClose: onFacebookModelClose,
    } = useDisclosure();
    const {
        isOpen: isDV360odelOpen,
        onOpen: onDV360ModelOpen,
        onClose: onDV360ModelClose,
    } = useDisclosure();

    const clientId = useSelector((state) => state.client.clientId);

    const navigate = useNavigate();
    const location = useLocation();

    const {id} = useParams();
    const {mutate, data} = useGetClientDetailsOnClick();
    const {mutate: updateCampaignStatus} = useUpdateCampaign();
    const {data: campaignData, mutate: mutateGetCampaign} = useGetCampaign();
    const {
        access_info: {roles},
    } = useRecoilValue(profile);


    useEffect(() => {
        return () => {
            navigate(`/campaign-briefs/${id}`)
        }
    }, [])

    useEffect(() => {
        if (roles) {
            let isMatch = roles.find((item) => item.includes(ROLES.CLIENT));
            if (isMatch) {
                SetIsRemoveTabs(false)
            } else {
                SetIsRemoveTabs(true)
            }
        }
    }, [roles]);

    useEffect(() => {
        if (clientId) {
            mutate({id: clientId});
            mutateGetCampaign({
                clientId,
                campaignId: id,
            });
        }
    }, [clientId]);

    useEffect(() => {
        if (campaignData?.campaign?.detail?.objective) {
            dispatch(
                updateCampaignFbObjective(campaignData.campaign.detail.objective)
            );
        }
    }, [campaignData]);

    useEffect(() => {
        if (location.state && location.state.isShowUpload) {
            setTimeout(() => {
                SetTabIndex(5);
            }, 1000);
        }
    }, []);

    const OnUpdateValue = () => {
        updateCampaignStatus({
            clientId,
            campaignBriefId: id,
            status: "Approved",
        });
        navigate("/campaign-briefs");
    };

    useEffect(() => {
        localStorage.removeItem("pageIndex")
    }, [])

    return (
        <>
            <Flex templateColumns="repeat(6, 1fr)" gap={4}>
                <Box
                    className="create-campaign-main"
                >
                    <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                        <Flex onClick={() => navigate("/campaign-briefs")} cursor={"pointer"}>
                            {/*<Center marginRight={2}>*/}
                            {/*    <BiArrowBack color="#A7A9BD"/>*/}
                            {/*</Center>*/}
                            <Center>
                                <Button
                                    leftIcon={<Image src={VectorIcon}/>}
                                    color="#79A9FF"
                                    fontWeight={500}
                                    border="2px"
                                    borderRadius={0}
                                    pr={6}
                                    fontFamily={"poppins"}
                                    bgColor={"white"}
                                    variant={"outline"}
                                >
                                    Back
                                </Button>
                            </Center>
                        </Flex>
                    </Heading>
                    <Heading fontSize="4xl" mb={4} color={"#757998"}
                        fontWeight={600} >
                        Create Campaign
                    </Heading>
                    <Tabs
                        index={tabIndex}
                        size="sm"
                        w="full"
                        colorScheme="black"
                        overflow="hidden"
                    >
                        <TabList
                            overflowX="auto"
                            css={css({
                                scrollbarWidth: "none",
                                "::-webkit-scrollbar": {display: "none"},
                                WebkitOverflowScrolling: "touch",
                                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                                border: "0 none",
                            })}
                        >
                            <For
                                each={CAMPAIGN_BRIEFS_TABS}
                                render={(tab, index) => {
                                    const isShowTab = (tab.hideTabForClient && !isRemoveTabs) ? false : true
                                    if (isShowTab) {
                                        return (
                                            <Tab
                                                key={index}
                                                onClick={() => {
                                                    SetTabIndex(index);
                                                }}
                                                whiteSpace="nowrap"
                                                isDisabled={
                                                    !id &&
                                                    (tab.title === "AD uploads" || tab.title === "Comments")
                                                }
                                            >
                                                {tab.title}
                                            </Tab>
                                        );
                                    }
                                }}
                            />
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Flex justifyContent="space-between">
                                    {/*<Heading color={TEXT_COLOR} size="md">*/}
                                    {/*    Current Status:{" "}*/}
                                    {/*    <span>{campaignData?.campaign?.status || "Draft"}</span>*/}
                                    {/*</Heading>*/}
                                    {id && campaignData?.campaign?.status == "Created" && (
                                        <Box display="flex">
                                            <Button
                                                size="sm"
                                                mr={2}
                                                colorScheme="green"
                                                backgroundColor="green.400"
                                                borderRadius={4}
                                                disabled={!clientId}
                                                onClick={() => OnUpdateValue()}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                size="sm"
                                                colorScheme="red"
                                                backgroundColor="red.400"
                                                borderRadius={4}
                                                disabled={!clientId}
                                                onClick={() =>
                                                    updateCampaignStatus({
                                                        clientId,
                                                        campaignBriefId: id,
                                                        status: "Rejected",
                                                    })
                                                }
                                            >
                                                Reject
                                            </Button>
                                        </Box>
                                    )}
                                </Flex>
                                <ChakraProvider theme={theme}>
                                    <CreateFormStepByStep  id={id}
                                                           clientDetails={
                                                               data?.client || JSON.parse(localStorage.getItem("client"))
                                                           }
                                                           campaignDetails={campaignData?.campaign} />
                                </ChakraProvider>
                            </TabPanel>
                            <TabPanel>
                                <AdUploadList/>
                            </TabPanel>
                            <TabPanel>
                                <AuditLogsList/>
                            </TabPanel>
                            <TabPanel>
                                <ContextWrapper>
                                    <StepByStepAdUpload SetTabIndex={SetTabIndex} />
                                </ContextWrapper>
                            </TabPanel>
                            <TabPanel>
                                <ContextWrapper>
                                    <ChakraProvider theme={theme}>
                                        <SelectAdType />
                                    </ChakraProvider>
                                </ContextWrapper>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
                <Box
                    position="fixed"
                    right="0"
                    width="22%"
                    padding={5}
                    colSpan={{base: 6, lg: tabIndex === 0 ? 2 : 0}}
                >
                    {isRemoveTabs && id && tabIndex === 0 && campaignData?.campaign?.status != "Created" && (
                        <VStack align="start" display="inline-grid">
                            <Button
                                colorScheme="blue"
                                backgroundColor="blue.400"
                                borderRadius={4}
                                px="10"
                                rightIcon={<BiPlusCircle/>}
                                onClick={onDV360ModelOpen}
                                disabled={!clientId}
                            >
                                DV360 Campaign
                            </Button>
                            <Button
                                colorScheme="blue"
                                backgroundColor="blue.400"
                                borderRadius={4}
                                px="10"
                                marginTop={5}
                                rightIcon={<BiPlusCircle/>}
                                onClick={onFacebookModelOpen}
                                disabled={!clientId}
                            >
                                Facebook Campaign
                            </Button>
                        </VStack>
                    )}

                    {id && tabIndex === 0 && (
                        <Comment clientId={clientId} campaignId={id}/>
                    )}
                </Box>

                <CreateDV360Campaign
                    isOpen={isDV360odelOpen}
                    onClose={onDV360ModelClose}
                    clientId={clientId}
                    data={campaignData?.campaign}
                />

                <CreateFacebookCampaign
                    isOpen={isFacebookModelOpen}
                    onClose={onFacebookModelClose}
                    clientId={clientId}
                    data={campaignData?.campaign}
                />
            </Flex>
        </>

    );
};

export default NewCreateCampaign;
