import {useNavigate, useParams} from "react-router-dom";
import {removeUserInClient, useGetClientDetails} from "../../../hooks/clients/useGetClientDetails";
import {useGetFbAccounts} from "../../../hooks/clients/useGetFbAccounts";
import {
    Avatar,
    Box,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    IconButton,
    Radio,
    RadioGroup,
    Text, useToast,
    VStack,
} from "@chakra-ui/react";
import {AddIcon, DeleteIcon} from "@chakra-ui/icons";
import {TEXT_COLOR} from "../../../layout/constant/MenuList";
import Upload from "rc-upload";
import {HiCamera, HiOutlineX} from "react-icons/hi";
import {Form, Formik} from "formik";
import InputBox from "../../../components/InputBox";
import TextAreaBox from "../../../components/TextAreaBox";
import {SelectControl} from "formik-chakra-ui";
import {clientDetails} from "../constant/InitialValues";
import React, {useEffect, useState} from "react";
import instance from "../../../helpers/axios";
import SuccessModal from "../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../components/PopupModal/ErrorModal";
import {useSelector} from "react-redux";
import WithAppContext from "../../../helpers/appContext";

const ClientDetailForClient = ({context}) => {
    // const { id } = useParams();
    const navigate = useNavigate();
    const {SuccessToaster, ErrorToaster} = context
    const client = JSON.parse(localStorage.getItem("client"));

    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const [type, setType] = useState("POST");
    const [url, setUrl] = useState("");
    const [industryType, setIndustryType] = useState(null);
    const [status, setStatus] = useState(null);
    const [fbPixels, setFbPixels] = useState([{name: "", pixelId: ""}]);
    const [users, setUsers] = useState([]);
    const [clientAvatar, setClientAvatar] = useState('');
    const [clientLogo, setClientLogo] = useState('')
    const [isClient, setIsClient] = useState(client.access_info.roles.includes('Client'))
    const toast = useToast();

    const {mutate, data: fbAccounts} = useGetFbAccounts();

    const {clientId} = useSelector((state) => state.client)
    const {data, refetch} = useGetClientDetails(clientId);

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        if (clientId) {
            refetch()
            setType("PUT");
            setUrl(`/client/${clientId}`);
        } else {
            setType("POST");
            setUrl("/client/");
        }
    }, [clientId]);

    useEffect(() => {
        if (data) {
            setIndustryType(data?.detail?.industryType);
            setStatus(data?.state);
            setFbPixels(data?.detail?.fbPixels);
            setUsers(data.users);
        }
    }, [data]);

    const removeUser = async (userId) => {
        if (userId && clientId) {
            await removeUserInClient(clientId, userId).then((res) => {
                if (res.message === 'SUCCESS') {
                    const _users = [...users];
                    const newArr = _users.filter(el => el.id !== userId);
                    setUsers(newArr);
                    SuccessToaster(`User successfully Removed`);
                }
            }).catch((err) => {
                ErrorToaster(err);
            })
        }
    }

    const uploadProps = {
        customRequest: (fileData) => {
            const data = new FormData();
            const {file, name, _boundary} = fileData;
            data.append("clientPic", file, name);

            return instance({
                method: "PUT",
                url: `/client/${clientId}/profile-pic`,
                data: data,
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${_boundary}`,
                },
            })
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        SuccessToaster(response.data?.data?.message);
                        const avatar = URL.createObjectURL(file);
                        setClientAvatar(avatar);
                    }
                })
                .catch((error) => {
                    ErrorToaster(error.response?.data?.message ||
                        "Something went wrong!");
                });
        },
        withCredentials: false,
    };

    const uploadLogo = {
        customRequest: (fileData) => {
            const data = new FormData();
            const {file, name, _boundary} = fileData;
            data.append("clientLogo", file, name);

            return instance({
                method: "PUT",
                url: `/client/${clientId}/profile-logo`,
                data: data,
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${_boundary}`,
                },
            })
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        SuccessToaster(response.data?.data?.message);
                        const logo = URL.createObjectURL(file);
                        setClientLogo(logo);
                    }
                })
                .catch((error) => {
                    ErrorToaster(error.response?.data?.message ||
                        "Something went wrong!");
                });
        },
        withCredentials: false,
    };

    return (
        <>
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                <GridItem
                    w="full"
                    colSpan={{base: 6, lg: 6}}
                    mb={{base: 3, lg: 0}}
                >
                    <Heading fontSize="30px" mb={7} color={"#757998"} fontFamily={"Lora"}>
                        Client Details
                    </Heading>
                </GridItem>
            </Grid>
            {data &&
            <HStack mt={6} spacing={10} align={"start"}>
                <Box pos="relative">
                    <Avatar
                        size="2xl"
                        src={clientAvatar ||
                        data?.other_info?.profile_pic_url &&
                        `${process.env.REACT_APP_API_URL}/uploads/${data?.other_info?.profile_pic_url}`
                        }
                        name={data.name}
                    />
                    {!isClient && <Upload {...uploadProps}>
                        <IconButton
                            pos="absolute"
                            right={0}
                            bottom={0}
                            variant="solid"
                            rounded="full"
                            colorScheme="blackAlpha"
                            aria-label="Search database"
                            icon={<HiCamera/>}
                        />
                    </Upload>}
                </Box>

                <Formik
                    enableReinitialize
                    initialValues={clientDetails(data)}
                    // validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        let data;
                        if (clientId) {
                            data = {
                                description: values.description,
                                fbAccountId: values.facebookAccountId,
                                fbPageId: values.facebookPageId,
                                name: values.companyName,
                                state: status,
                                detail: {
                                    address: values.address,
                                    companyName: values.companyName,
                                    contactName: values.contactName,
                                    email: values.email,
                                    industry: values.industry,
                                    industryType: industryType,
                                    phone: values.phone,
                                    productAndServices:
                                    values.productAndServices,
                                    fbPixels: fbPixels,
                                },
                            };
                        } else {
                            data = {
                                description: values.description,
                                fbAccountId: values.facebookAccountId,
                                fbPageId: values.facebookPageId,
                                name: values.companyName,
                                detail: {
                                    address: values.address,
                                    companyName: values.companyName,
                                    contactName: values.contactName,
                                    email: values.email,
                                    industry: values.industry,
                                    industryType: industryType,
                                    phone: values.phone,
                                    productAndServices:
                                    values.productAndServices,
                                    fbPixels: fbPixels,
                                },
                            };
                        }
                        await instance({
                            method: type,
                            url: url,
                            withCredentials: false,
                            data: data,
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    setSuccessModal(true);
                                }
                            })
                            .catch((error) => {
                                setDescription(error.response.data.message);
                                setErrorModal(true);
                            });
                    }}
                >
                    {({values, errors, handleChange}) => (
                        <VStack as={Form} w="70%" align={"start"} spacing={4}>
                            {!isClient ? <><InputBox
                                name="companyName"
                                label="Company name"
                                value={values.companyName}
                                onChange={handleChange}
                            />
                                <InputBox
                                    name="contactName"
                                    label="Contact Full Name"
                                    value={values.contactName}
                                    onChange={handleChange}
                                />
                                <InputBox
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                /></> : <>
                                <Text color={"#A7A9BD"} fontWeight={600} fontSize={14} style={{"margin-bottom": "-5px"}}>Company Name</Text>
                                <Text color={"#757998"} fontWeight={600}
                                      style={{"margin-bottom": "12px"}}>{values.companyName}</Text><Text
                                color={"#A7A9BD"} fontWeight={600} fontSize={14} style={{"margin-bottom": "-5px"}}>Contact Full Name</Text>
                                <Text color={"#757998"} fontWeight={600}
                                      style={{"margin-bottom": "12px"}}>{values.contactName}</Text><Text
                                color={"#A7A9BD"} fontWeight={600} fontSize={14} style={{"margin-bottom": "-5px"}}>Email</Text>
                                <Text color={"#757998"} fontWeight={600}
                                      style={{"margin-bottom": "12px"}}>{values.email}</Text><Text
                                color={"#A7A9BD"} fontWeight={600} fontSize={14} style={{"margin-bottom": "-5px"}}>Phone</Text>
                                <Text color={"#757998"} fontWeight={600}
                                      style={{"margin-bottom": "12px"}}>{values.phone}</Text><Text
                                color={"#A7A9BD"} fontWeight={600} fontSize={14} style={{"margin-bottom": "-5px"}}>Address</Text>
                                <Text color={"#757998"} fontWeight={600}
                                      style={{"margin-bottom": "12px"}}>{values.address}</Text>
                                </>}
                            {!isClient && <>
                            <HStack spacing={4} w="full">
                                <InputBox
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                />
                                <InputBox
                                    name="industry"
                                    label="Industry"
                                    value={values.industry}
                                    onChange={handleChange}
                                />
                            </HStack>
                                    <InputBox
                                name="description"
                                label="Description"
                                value={values.description}
                                onChange={handleChange}
                            />
                                <InputBox
                                    name="productAndServices"
                                    label="Product & Services "
                                    value={values.productAndServices}
                                    onChange={handleChange}
                                    /> </>}
                            {!isClient && <> <HStack spacing={4} w="full">
                                <TextAreaBox
                                    name="address"
                                    label="Address"
                                    value={values.address}
                                    onChange={handleChange}
                                />
                                 <FormControl as="fieldset">
                                    <RadioGroup
                                        // defaultValue="B2B"
                                        value={industryType}
                                        onChange={setIndustryType}
                                        name="industryType"
                                    >
                                        <HStack spacing="30px">
                                            <Radio size="lg" value="B2B">
                                                B2B
                                            </Radio>
                                            <Radio size="lg" value="B2C">
                                                B2C
                                            </Radio>
                                        </HStack>
                                    </RadioGroup>
                                </FormControl>
                            </HStack>
                                {clientId && (
                                    <HStack spacing={4} w="full">
                                        <FormControl as="fieldset">
                                            <FormLabel
                                                color={"#A7A9BD"}
                                                as="legend"
                                                fontSize="sm"
                                            >
                                                Status
                                            </FormLabel>
                                            <RadioGroup
                                                value={status}
                                                onChange={setStatus}
                                                name="status"
                                            >
                                                <HStack spacing="24px">
                                                    <Radio value="ACTIVE">
                                                        ACTIVE
                                                    </Radio>
                                                    <Radio value="INACTIVE">
                                                        INACTIVE
                                                    </Radio>
                                                    <Radio value="ON_HOLD">
                                                        ON HOLD
                                                    </Radio>
                                                </HStack>
                                            </RadioGroup>
                                        </FormControl>
                                    </HStack>
                                )}

                                <Divider/>
                                <FormLabel
                                    as="legend"
                                    color={"#A7A9BD"}
                                    fontSize="md"
                                >
                                    Logo
                                </FormLabel>
                                <Box pos="relative">
                                    <Avatar
                                        size="xl"
                                        src={clientLogo ||
                                        data?.other_info?.profile_logo_url &&
                                        `${process.env.REACT_APP_API_URL}/uploads/${data?.other_info?.profile_logo_url}`
                                        }
                                        name={data.name}
                                    />
                                    <Upload {...uploadLogo}>
                                        <IconButton
                                            pos="absolute"
                                            right={0}
                                            bottom={0}
                                            variant="solid"
                                            rounded="full"
                                            colorScheme="blackAlpha"
                                            aria-label="Search database"
                                            icon={<HiCamera/>}
                                        />
                                    </Upload>
                                </Box>
                                <Divider/>
                                <FormControl as="fieldset">
                                    <FormLabel
                                        as="legend"
                                        color={"#A7A9BD"}
                                        fontSize="sm"
                                    >
                                        Facebook Pixels
                                    </FormLabel>
                                    {fbPixels?.map((el, index) => (
                                        <HStack key={index} spacing="30px" mb={5}>
                                            <InputBox
                                                id="facebookPixels"
                                                name="facebookPixels"
                                                value={el?.pixelId}
                                                // value={`${el?.name} | ${el?.pixelId}`}
                                                inputProps={{
                                                    variant: "outline",
                                                    border: "2px",
                                                    borderRadius: 0,
                                                    borderColor: "gray",
                                                    type: "text",
                                                }}
                                                onChange={(e) => {
                                                    const val = [...fbPixels];
                                                    // const [name, pixel] = (e.target.value).split("|")
                                                    val[index].pixelId = e.target.value;
                                                    // val[index].name = name.trim()
                                                    setFbPixels(val);
                                                }}
                                            />
                                            <DeleteIcon
                                                color="red"
                                                cursor="pointer"
                                                onClick={() =>
                                                    setFbPixels(
                                                        fbPixels.filter((el, ind) => ind != index)
                                                    )
                                                }
                                            />
                                        </HStack>
                                    ))}
                                    <HStack
                                        spacing="10px"
                                        onClick={() =>
                                            setFbPixels([
                                                ...fbPixels,
                                                {name: "", pixelId: ""},
                                            ])
                                        }
                                    >
                                        <AddIcon color={"#757998"} fontWeight={600} cursor="pointer"/>
                                        <Text color={"#757998"} fontWeight={600}>New Line</Text>
                                    </HStack>
                                </FormControl>
                                <Divider/>
                                <SelectControl
                                    color={"#A7A9BD"}
                                    fontWeight={600}
                                    name="facebookAccountId"
                                    label="Facebook Account ID"
                                    value={values.facebookAccountId}
                                    onChange={handleChange}
                                >
                                    {fbAccounts?.adAccounts?.length &&
                                    fbAccounts?.adAccounts.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))}
                                </SelectControl>
                                <InputBox

                                    name="facebookPageId"
                                    label="Contact Page Id"
                                    value={values.facebookPageId}
                                    onChange={handleChange}
                                />
                                <Divider/>
                                <div style={{width: "100%", color: "#A7A9BD"}}>
                                    <label>Associated Users</label>
                                    <Box py={"10"} display={"grid"} gridTemplateColumns={"repeat(6, 1fr)"} gap={"5px"}
                                         fontWeight={600}>
                                        {users && users.map((item, index) => {
                                            return (
                                                <Box position="relative" key={index} display={"flex"}
                                                     flexDirection={"column"} alignItems={"center"} gap={2}
                                                     marginBottom={6}>
                                                    <Avatar
                                                        width={"100px"}
                                                        height={"100px"}
                                                        name={item.name}
                                                        src={`${process.env.REACT_APP_API_URL}/uploads/${item?.other_info?.profile_pic_url}`}
                                                    >
                                                    </Avatar>
                                                    {!item.access_info.roles.includes('Admin') &&
                                                    <IconButton
                                                        onClick={() => removeUser(item.id)}
                                                        pos="absolute"
                                                        right="7"
                                                        top={0}
                                                        variant="solid"
                                                        rounded="full"
                                                        colorScheme="red"
                                                        aria-label="Search database"
                                                        icon={<HiOutlineX/>}
                                                    />
                                                    }
                                                    <Text>{item.name}</Text>

                                                </Box>
                                            )
                                        })
                                        }
                                        {client.access_info.roles.includes('Admin') &&
                                        <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                                            <Avatar
                                                width={"100px"}
                                                height={"100px"}
                                                name={'+'}
                                                cursor="pointer"
                                                onClick={() => navigate('/user')}
                                            >
                                            </Avatar>
                                            <Text>Request New User</Text>
                                        </Box>

                                        }
                                    </Box>
                                </div>
                                {/*<SubmitButton*/}
                                {/*    type="submit"*/}
                                {/*    size="sm"*/}
                                {/*    colorScheme="blue"*/}
                                {/*    px="14"*/}
                                {/*    rounded="full"*/}
                                {/*>*/}
                                {/*    {clientId ? "Update" : "Add"}*/}
                                {/*</SubmitButton>*/}
                            </>}
                        </VStack>
                    )}
                </Formik>
            </HStack>
            }

            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => setSuccessModal(false)}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
};

export default WithAppContext(ClientDetailForClient);
