import React, { useEffect, useState } from "react";
import {
  Center,
  css,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { TEXT_COLOR } from "../../../../../../layout/constant/MenuList";
import "../../../../style/FacebookAdUpload.css";
import  FacebookCarouselAdDetails  from "./FacebookCarouselAdDetails";
import AdPreview from "../../AdPreview";
import { useGetAdUpload } from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Comments } from "../Comments";
import { useSelector } from "react-redux";
import AuditLogsList from "../../AuditLogsList";
import FullPageLoader from "../../../../../../components/FullPageLoader";
import WithAppContext from "../../../../../../helpers/appContext";
import {ArrowBackIcon} from "@chakra-ui/icons";

const FacebookCarouselAdUpload = ({ context }) => {
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const [previewData, setPreviewData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const { id, fbId } = useParams();
  const clientId = useSelector((state) => state.client.clientId);

  const { data, refetch } = useGetAdUpload(clientId, id, fbId);

  const { appLoading } = context

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (fbId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${id}/ad-upload/${fbId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload`);
    }
  }, [fbId, clientId]);

  return (
    <>
      {appLoading && <FullPageLoader />}
      <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
        <GridItem
          w="full"
          colSpan={{ base: 6, lg: 6 }}
          mb={{ base: 3, lg: 0 }}
        >
          <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
            <Flex
              onClick={() => navigate(`/campaign-briefs/${id}`)}
              cursor={"pointer"}
            >
              {/*<Center marginRight={2}>*/}
              {/*  <BiArrowBack color="#A7A9BD" />*/}
              {/*</Center>*/}
                <Button
                    color='#79A9FF'
                    variant='outline'
                    fontFamily={"poppins"}
                    fontSize={14}
                    lineHeight={21}
                    leftIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
            </Flex>
          </Heading>
          <Heading fontSize="4xl" mb={4} color={TEXT_COLOR}>
            Facebook Carousel Ad Upload
          </Heading>
          <Tabs
            onChange={(index) => setTabIndex(index)}
            index={tabIndex}
            size="sm"
            w="full"
            colorScheme="black"
            overflow="hidden"
          >
            <TabList
              overflowX="auto"
              css={css({
                scrollbarWidth: "none",
                "::-webkit-scrollbar": { display: "none" },
                WebkitOverflowScrolling: "touch",
                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                border: "0 none",
              })}
            >
              <Tab whiteSpace="nowrap">Details</Tab>
              <Tab whiteSpace="nowrap" isDisabled={previewData.length < 2}>Preview</Tab>
              <Tab whiteSpace="nowrap" isDisabled={!fbId}>Comments</Tab>
              <Tab whiteSpace="nowrap">Audit Logs</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <FacebookCarouselAdDetails
                  handlePreviewData={setPreviewData}
                  data={data?.adUpload}
                  url={url}
                  method={method}
                  clientId={clientId}
                  campaignId={id}
                  adUploadId={fbId}
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel>
                <AdPreview data={previewData} />
              </TabPanel>
              <TabPanel>
                <Comments
                  clientId={clientId}
                  campaignId={id}
                  facebookAdId={fbId}
                />
              </TabPanel>
              <TabPanel>
                <AuditLogsList />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </>
  );
};

export default WithAppContext(FacebookCarouselAdUpload);
