import React, {useEffect, useState} from 'react';
import {Box, Center, Select, Spacer} from '@chakra-ui/react'
import {Flex, Heading} from "@chakra-ui/layout";
import {useSelector} from "react-redux";
import {useGetLookerStudioReports} from "../../hooks/looker-studio/useGetLookerStudioReports";
import {useGetClientDetails} from "../../hooks/clients/useGetClientDetails";
import instance from "../../helpers/axios";
import FullPageLoader from "../../components/FullPageLoader";


function Reports(props) {
    const clientId = useSelector((state) => state.client.clientId);
    const {data, refetch} = useGetLookerStudioReports(clientId);
    const {data: clientData} = useGetClientDetails(clientId)
    const [isValid, setIsValid] = useState(false)
    const [reports, setReports] = useState([])
    const [selectedReport, setSelectedReport] = useState(null)

    useEffect(() => {
        setReports(data)
        if (data && data.length !== 0) {
            setSelectedReport(data[0]?.report_url)
        }
    }, [data])

    // async function getReports() {
    //     setIsLoading(true)
    //     const match = selectedReport.match(/\/reporting\/([\w-]+)\//);
    //     const reportId = match[1];
    //     await instance({
    //         method: "GET",
    //         url: `/looker-reports?clientId=${clientData.id}`,
    //     })
    //         .then((response) => {
    //             const ids = response.data.data.reports
    //             if (ids.includes(reportId)) {
    //                 setIsValid(true)
    //             } else {
    //                 setIsValid(false)
    //             }
    //             setIsLoading(false)
    //         })
    //         .catch((error) => {
    //             setIsLoading(false)
    //             console.log(error)
    //         });
    // }

    // useEffect(() => {
    //     if (selectedReport && clientData?.id) {
    //         getReports()
    //     }
    // }, [selectedReport, clientData?.id])

    return (
        <div style={{maxWidth: "90vw", maxHeight: "80vh", width: "100%", height: "80vh"}}>
            <Flex minWidth='max-content' alignItems='center' gap='4'>
                <Box p='5'>
                    <Heading color="#757998"
                             fontWeight="600"
                             fontFamily="Lora"
                             size="lg">{clientData?.name}</Heading>
                </Box>
                <Spacer/>
                <Select maxWidth={500} value={selectedReport} onChange={(e) => setSelectedReport(e.target.value)}>
                    {reports?.length > 0 ? reports.map((report, index) => {
                        return (
                            <option key={index}
                                    value={report.report_url}>{report.name}</option>
                        )
                    }) : <option>Select Report</option>
                    }
                </Select>
            </Flex>
            <div style={{
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "8px",
                overflow: "hidden"
            }}>
                {selectedReport &&
                <iframe allowFullScreen={true} id='report' src={selectedReport} width="100%" height='100%'/>}
                {!selectedReport &&
                <Center width="100%" height='100%' fontSize='xl'>You dont have any reports right now</Center>}
            </div>
        </div>
    );
}


export default Reports;
