import {Box, Button, Checkbox, FormLabel, Grid, GridItem, Heading, IconButton, Radio, Text,} from "@chakra-ui/react";
import {RadioGroupControl} from "formik-chakra-ui";
import React, {useEffect, useState} from "react";
import InputBox from "../../../../components/InputBox";
import MultiSelectInputBox from "../../../../components/MultiSelectInputBox";
import "../../style/CampaignDetails.css";
import {CreativeSizes, CreativeUnit} from "../../constant/SelectValues"
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import {Flex} from "@chakra-ui/layout";
import BudgetAdOn from "./BudgetAdOn";
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import _ from "lodash";

const CreativeStep = ({ values, setFieldValue, errors, activeStep, steps, prevStep, nextStep }) => {
    const [selectedCreativeUnit, setSelectedCreativUnit] = useState([]);
    const [selectedCreativeSize, setSelectedCreativeSize] = useState([]);

    const [isError, setIsError] = useState(false)



    useEffect(() => {
        if(errors?.detail?.socialPage?.length > 0) {
            setIsError(true);
        }  else {
            setIsError(false);
        }
    },[errors?.detail?.socialPage])

    useEffect(() => {
        if (values?.detail?.creativeSize?.length) {
            setSelectedCreativeSize(
                values.detail.creativeSize.map((el) => {
                    const id = CreativeSizes.filter((e) => e.value === el);
                    return { value: el, label: id?.[0]?.label };
                })
            );
        }
    }, [values?.detail?.creativeSize]);

    useEffect(() => {
        if (values?.detail?.creativeUnit?.length) {
            setSelectedCreativUnit(
                values.detail.creativeUnit.map((el) => {
                    const id = CreativeUnit.filter((e) => e.value === el);
                    return { value: el, label: id?.[0]?.label };
                })
            );
        }
    }, [values?.detail?.creativeUnit]);

    return (
        <Box p={4}>
            <Box mb={6} display="flex" justifyContent="space-between">
            <Heading fontSize="xl" color={"#757998"}>
                Creative
            </Heading>
            <Heading fontSize="md" color="#A7A9BD">
                2 steps to complete
            </Heading>
            </Box>
            <Grid className="flex-for-mobile" templateColumns="repeat(2, 1fr)" gap={"8px"}>
                <GridItem mb={6} colSpan={2} display="flex" justifyContent="space-between">

                </GridItem>
                <GridItem>
                    <MultiSelectInputBox
                        options={CreativeUnit}
                        value={selectedCreativeUnit}
                        label="Creative Formats"
                        name="detail.creativeUnit"
                        placeholder={`Select...`}
                        onChange={(e) => {
                            setSelectedCreativUnit(e.map((v) => v))
                            setFieldValue(
                                'detail.creativeUnit', 
                                e.map(el => el.value)
                            )
                        }}
                    />
                </GridItem>
                <GridItem>
                    <MultiSelectInputBox
                        options={CreativeSizes}
                        value={selectedCreativeSize}
                        label="Creative Versions"
                        name="detail.creativeSize"
                        placeholder={`Select...`}
                        onChange={(e) => {
                            setSelectedCreativeSize(e.map((v) => v))
                            setFieldValue(
                                'detail.creativeSize', 
                                e.map(el => el.value)
                            )
                        }}
                    />
                </GridItem>
                <GridItem colSpan={2}>
                    <InputBox
                        name="detail.linkToAdServing"
                        placeholder="Link to Ad Serving"
                    />
                </GridItem>
                <GridItem colSpan={2}>
                    <Box className="creative-item">
                        <FormLabel color="gray" fontSize="sm">
                            Can we pixel your landing page? (Conversion, Events,
                            and Re targeting)
                        </FormLabel>
                        <RadioGroupControl name="detail.canPixelLandingPage">
                            <Radio colorScheme="yellow" value="Yes">
                                Yes
                            </Radio>
                            <Radio colorScheme="yellow" value="No">
                                No
                            </Radio>
                        </RadioGroupControl>
                    </Box>
                    <Box className="creative-item">
                        <FormLabel color="gray" fontSize="sm">
                            Do you use Google Tag Manager?
                        </FormLabel>
                        <Checkbox colorScheme="yellow">Yes</Checkbox>
                        <InputBox name="detail.googleTagManager" />
                    </Box>
                    <Box className="creative-item">
                        <FormLabel color="gray" fontSize="sm">
                            Number of “Unique Page views” per month
                        </FormLabel>
                        <InputBox name="detail.numUniquePageViews" />
                    </Box>
                </GridItem>
                <GridItem colSpan={2}>
                    <InputBox
                        name="detail.numberOfCreativeExecutions"
                        placeholder="Number Of CreativeStep Executions"
                    />
                    {/*<Box>*/}
                    {/*    {budget.map((budget, index) => {*/}
                    {/*        return (*/}
                    {/*    <Flex alignItems="flex-end" gap={5}>*/}
                    {/*        <GridItem w="60%">*/}
                    {/*            <InputBox*/}
                    {/*                label="Social Pages"*/}
                    {/*                name={`detail.numberOfCreativeExecutions${[index]}`}*/}
                    {/*                placeholder="Enter Page Name"*/}
                    {/*            />*/}
                    {/*        </GridItem>*/}
                    {/*        <Text fontSize='2xl'>*/}
                    {/*            =*/}
                    {/*        </Text>*/}
                    {/*        <GridItem w='100%'>*/}
                    {/*            <InputBox*/}
                    {/*                placeholder='Enter social page url'*/}
                    {/*                name={`detail.numberOfCreativeExecutions${[index]}`}*/}
                    {/*                type="number"/>*/}
                    {/*        </GridItem>*/}
                    {/*        <IconButton*/}
                    {/*            size="sm"*/}
                    {/*            variant="raised"*/}
                    {/*            isRound*/}
                    {/*            color="white !important"*/}
                    {/*            background="#ff000096"*/}
                    {/*            // onClick={() => RemoveBudgetLine(index)}*/}
                    {/*            icon={<CloseIcon color="#ffffff"/>}*/}
                    {/*        />*/}
                    {/*    </Flex>)})}*/}
                    {/*    <Flex marginTop={3} gap={3} alignItems="center">*/}
                    {/*        <IconButton*/}
                    {/*            size="sm"*/}
                    {/*            variant="raised"*/}
                    {/*            isRound*/}
                    {/*            color="white !important"*/}
                    {/*            background="#59ab9e"*/}
                    {/*            // onClick={AddNewBudgetLine}*/}
                    {/*            icon={<AddIcon color="#ffffff"/>}*/}
                    {/*        />*/}
                    {/*        <Text>New Line</Text>*/}
                    {/*    </Flex>*/}
                    {/*</Box>*/}
                </GridItem>
                <GridItem colSpan={2}>
                    <InputBox name="detail.socialPage" placeholder="Social Page*" />
                </GridItem>

            </Grid>
            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>

                <Button mr={4} isDisabled={isError} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                    Next
                </Button>

            </Flex>
        </Box>
    );
};

export default CreativeStep;
