import {useGlobalContext} from "../store/WrapContext";
import {Form, FormikProvider, useFormik} from "formik";
import {googleCSVInitialValue, googleCsvLengths, googleFormLenght} from "../../../../../../constant/InitialValues";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";
import instance from "../../../../../../../../helpers/axios";
import "../css/mobileView.css"
import {
    Box,
    Button, Center,
    CircularProgress,
    CircularProgressLabel, css, Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Heading, Text
} from "@chakra-ui/react";
import {InputControl} from "formik-chakra-ui";
import {TEXT_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";

function MasterEditForm({masterAd, formValue, handleUpdate}) {

    const navigate = useNavigate()
    const initialValues = formValue;

    let schema = yup.object().shape({
        campaign: yup.string().required("campaign Name is Required"),
        ad_group: yup.string().required("Ad Group Name is Required"),
        headline_1: yup.string().required("Headline 1 is Required").max(30, "Must be 30 character"),
        headline_2: yup.string().required("Headline 2 is Required").max(30, "Must be 30 character"),
        headline_3: yup.string().required("Headline 3 is Required").max(30, "Must be 30 character"),
        headline_4: yup.string().max(30, "Must be 30 character"),
        headline_5: yup.string().max(30, "Must be 30 character"),
        headline_6: yup.string().max(30, "Must be 30 character"),
        headline_7: yup.string().max(30, "Must be 30 character"),
        headline_8: yup.string().max(30, "Must be 30 character"),
        headline_9: yup.string().max(30, "Must be 30 character"),
        headline_10: yup.string().max(30, "Must be 30 character"),
        headline_11: yup.string().max(30, "Must be 30 character"),
        headline_12: yup.string().max(30, "Must be 30 character"),
        headline_13: yup.string().max(30, "Must be 30 character"),
        headline_14: yup.string().max(30, "Must be 30 character"),
        headline_10_char_count: yup.string().max(30, "Must be 30 character"),
        headline_11_char_count: yup.string().max(30, "Must be 30 character"),
        headline_12_char_count: yup.string().max(30, "Must be 30 character"),
        headline_13_char_count: yup.string().max(30, "Must be 30 character"),
        headline_14_char_count: yup.string().max(30, "Must be 30 character"),
        description_1: yup.string().required("Description1 is Required").max(90, "Must be 90 character"),
        description_2: yup.string().required("Description2 is Required").max(90, "Must be 90 character"),
        final_url: yup.string().required("Final Url is Required")
    })


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, {resetForm}) => {
            handleUpdate(values)
        },
    });

    const {
        errors,
        values,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
    } = formik;

    const {id: campaignId, googleId, instagramId} = useParams();

    return (
        <>
            <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                <Flex
                    onClick={() => navigate(`/campaign-briefs/${campaignId}/edit-adUpload/${googleId}`, {
                        state: {isUpdate: true},
                    })}
                    cursor={"pointer"}
                >
                    <Center marginRight={2}>
                        <BiArrowBack color="#A7A9BD"/>
                    </Center>
                    <Center>
                        <Text
                            css={css({
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#A7A9BD",
                            })}
                        >
                            Back
                        </Text>
                    </Center>
                </Flex>
            </Heading>
            <Box marginBottom={4}>
                <Text fontSize='xl' fontWeight={600}>Update Master Data</Text>
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid templateColumns='repeat(2, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="ad_group" color="gray" fontSize="sm">
                                    campaign

                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="campaign"
                                        name="campaign"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    {/*<CircularProgress*/}
                                    {/*    max={googleCsvLengths.adName}*/}
                                    {/*    value={values.ad_group.length}*/}
                                    {/*    color={*/}
                                    {/*        values.ad_group.length > googleCsvLengths.adName*/}
                                    {/*            ? "red.400"*/}
                                    {/*            : "green.400"*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <CircularProgressLabel>*/}
                                    {/*        {values.ad_group.length >*/}
                                    {/*        googleCsvLengths.adName*/}
                                    {/*            ? googleCsvLengths.adName -*/}
                                    {/*            values.ad_group.length*/}
                                    {/*            : values.ad_group.length}*/}
                                    {/*    </CircularProgressLabel>*/}
                                    {/*</CircularProgress>*/}
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="ad_group" color="gray" fontSize="sm">
                                    Ad Group - max 512 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="ad_group"
                                        name="ad_group"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleCsvLengths.adName}
                                        value={values.ad_group.length}
                                        color={
                                            values.ad_group.length > googleCsvLengths.adName
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.ad_group.length >
                                            googleCsvLengths.adName
                                                ? googleCsvLengths.adName -
                                                values.ad_group.length
                                                : values.ad_group.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_1" color="gray" fontSize="sm">
                                    Heading 1 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_1"
                                        name="headline_1"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_1.length}
                                        color={
                                            values.headline_1.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_1.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_1.length
                                                : values.headline_1.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>

                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_2" color="gray" fontSize="sm">
                                    Heading 2 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_2"
                                        name="headline_2"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_2.length}
                                        color={
                                            values.headline_2.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_2.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_2.length
                                                : values.headline_2.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_3" color="gray" fontSize="sm">
                                    Heading 3 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_3"
                                        name="headline_3"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_3.length}
                                        color={
                                            values.headline_3.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_3.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_3.length
                                                : values.headline_3.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_4" color="gray" fontSize="sm">
                                    Heading 4 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_4"
                                        name="headline_4"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_4.length}
                                        color={
                                            values.headline_4.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_4.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_4.length
                                                : values.headline_4.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_5" color="gray" fontSize="sm">
                                    Heading 5 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_5"
                                        name="headline_5"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_5.length}
                                        color={
                                            values.headline_5.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_5.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_5.length
                                                : values.headline_5.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_6" color="gray" fontSize="sm">
                                    Heading 6 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_6"
                                        name="headline_6"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_6.length}
                                        color={
                                            values.headline_6.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_6.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_6.length
                                                : values.headline_6.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_7" color="gray" fontSize="sm">
                                    Heading 7 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_7"
                                        name="headline_7"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_7.length}
                                        color={
                                            values.headline_7.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_7.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_7.length
                                                : values.headline_7.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_8" color="gray" fontSize="sm">
                                    Heading 8 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_8"
                                        name="headline_8"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_8.length}
                                        color={
                                            values.headline_8.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_8.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_8.length
                                                : values.headline_8.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_9" color="gray" fontSize="sm">
                                    Heading 9 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_9"
                                        name="headline_9"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_9.length}
                                        color={
                                            values.headline_9.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_9.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_9.length
                                                : values.headline_9.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_9_char_count" color="gray" fontSize="sm">
                                    Heading 9 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_9_char_count"
                                        name="headline_9_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_9_char_count.length}
                                        color={
                                            values.headline_9_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_9_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_9_char_count.length
                                                : values.headline_9_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_10" color="gray" fontSize="sm">
                                    Heading 10 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_10"
                                        name="headline_10"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_10.length}
                                        color={
                                            values.headline_10.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_10.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_10.length
                                                : values.headline_10.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_10_char_count" color="gray" fontSize="sm">
                                    Heading 10 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_10_char_count"
                                        name="headline_10_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_10_char_count.length}
                                        color={
                                            values.headline_10_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_10_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_10_char_count.length
                                                : values.headline_10_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_11" color="gray" fontSize="sm">
                                    Heading 11 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_11"
                                        name="headline_11"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_11.length}
                                        color={
                                            values.headline_11.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_11.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_11.length
                                                : values.headline_11.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_11_char_count" color="gray" fontSize="sm">
                                    Heading 11 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_11_char_count"
                                        name="headline_11_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_11_char_count.length}
                                        color={
                                            values.headline_11_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_11_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_11_char_count.length
                                                : values.headline_11_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_12" color="gray" fontSize="sm">
                                    Heading 12 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_12"
                                        name="headline_12"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_12.length}
                                        color={
                                            values.headline_12.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_12.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_12.length
                                                : values.headline_12.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_12_char_count" color="gray" fontSize="sm">
                                    Heading 12 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_12_char_count"
                                        name="headline_12_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_12_char_count.length}
                                        color={
                                            values.headline_12_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_12_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_12_char_count.length
                                                : values.headline_12_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_13" color="gray" fontSize="sm">
                                    Heading 13 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_13"
                                        name="headline_13"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_13.length}
                                        color={
                                            values.headline_13.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_13.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_13.length
                                                : values.headline_13.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_13_char_count" color="gray" fontSize="sm">
                                    Heading 13 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_13_char_count"
                                        name="headline_13_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_13_char_count.length}
                                        color={
                                            values.headline_13_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_13_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_13_char_count.length
                                                : values.headline_13_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_14" color="gray" fontSize="sm">
                                    Heading 14 - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_14"
                                        name="headline_14"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_14.length}
                                        color={
                                            values.headline_14.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_14.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_14.length
                                                : values.headline_14.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="headline_14_char_count" color="gray" fontSize="sm">
                                    Heading 14 Character Count - max 30 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="headline_14_char_count"
                                        name="headline_14_char_count"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.heading}
                                        value={values.headline_14_char_count.length}
                                        color={
                                            values.headline_14_char_count.length > googleFormLenght.heading
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.headline_14_char_count.length >
                                            googleFormLenght.heading
                                                ? googleFormLenght.heading -
                                                values.headline_14_char_count.length
                                                : values.headline_14_char_count.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="description_1" color="gray" fontSize="sm">
                                    Description 1 - max 90 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="description_1"
                                        name="description_1"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.description}
                                        value={values.description_1.length}
                                        color={
                                            values.description_1.length > googleFormLenght.description
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.description_1.length >
                                            googleFormLenght.description
                                                ? googleFormLenght.description -
                                                values.description_1.length
                                                : values.description_1.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="description_2" color="gray" fontSize="sm">
                                    Description 2 - max 90 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="description_2"
                                        name="description_2"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.description}
                                        value={values.description_2.length}
                                        color={
                                            values.description_2.length > googleFormLenght.description
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.description_2.length >
                                            googleFormLenght.description
                                                ? googleFormLenght.description -
                                                values.description_2.length
                                                : values.description_2.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns='repeat(3, 1fr)' marginBottom={4} gap={6}>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="description_3" color="gray" fontSize="sm">
                                    Description 3 - max 90 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="description_3"
                                        name="description_3"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.description}
                                        value={values.description_3.length}
                                        color={
                                            values.description_3.length > googleFormLenght.description
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.description_3.length >
                                            googleFormLenght.description
                                                ? googleFormLenght.description -
                                                values.description_3.length
                                                : values.description_3.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="description_4" color="gray" fontSize="sm">
                                    Description 4 - max 90 characters
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="description_4"
                                        name="description_4"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <CircularProgress
                                        max={googleFormLenght.description}
                                        value={values.description_4.length}
                                        color={
                                            values.description_4.length > googleFormLenght.description
                                                ? "red.400"
                                                : "green.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {values.description_4.length >
                                            googleFormLenght.description
                                                ? googleFormLenght.description -
                                                values.description_4.length
                                                : values.description_4.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </div>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <FormControl>
                                <FormLabel htmlFor="final_url" color="gray" fontSize="sm">
                                    Final URL
                                </FormLabel>
                                <div className="input-box">
                                    <InputControl
                                        id="final_url"
                                        name="final_url"
                                        placeholder=""
                                        inputprops={{
                                            variant: "outline",
                                            type: "text",
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Button type={"submit"}>Update</Button>
                </Form>
            </FormikProvider>
        </>
    )
}

export default MasterEditForm