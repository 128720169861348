import {
    Button,
    Divider,
    Heading,
    Stack,
    Text, useToast,
} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";
import {IoMdAddCircle} from "react-icons/io";
import Datatable from "../../components/Datatable";
import {TEXT_COLOR} from "../../layout/constant/MenuList";
import "../../pages/CampaignBriefs/style/AdUploadList.css";
import Actions from "./Actions";
import instance from "../../helpers/axios";
import ReportDialog from "./ReportDialog";
import {useSelector} from "react-redux";
import {useGetLookerStudioReports} from "../../hooks/looker-studio/useGetLookerStudioReports";
import {useGetClientDetails} from "../../hooks/clients/useGetClientDetails";

const LookerReports = () => {
    const toast = useToast();
    const [reports, setReports] = useState([])
    const [open, setOpen] = useState(false)
    const clientId = useSelector((state) => state.client.clientId);
    const {data: clientData} = useGetClientDetails(clientId)
    const clientEmail = (clientData?.detail?.email)
    const {data, refetch} = useGetLookerStudioReports(clientId);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setReports(data)
    }, [data]);

    function handleOpen() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Report URL",
                accessor: "report_url",
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
                Cell: (data) => {
                    return <Actions row={data.row.original} />;
                },
            },
        ],
        []
    );


    const onSubmit = async (values) => {
        await instance({
            method: "POST",
            url: `/client/${clientId}/looker-studio`,
            data: {
                name: values.name,
                report_url: values.report_url,
                client_email: clientEmail
            },
        })
            .then((response) => {
                handleClose()
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()

            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    return (
        <div className="ad-upload-list">
            <Heading
                color={TEXT_COLOR}
                fontWeight="500"
                size="lg"
                my={5}
                mb={7}
            >
                Reports
            </Heading>
            <div className="search">
                <Stack spacing={4}>
                </Stack>
                <Button
                    colorScheme="blue"
                    backgroundColor="#3B82F6"
                    borderRadius={4}
                    px="10"
                    marginTop={5}
                    rightIcon={<IoMdAddCircle/>}
                    onClick={handleOpen}
                >
                    Add Report
                </Button>
            </div>

            <Divider/>
            <Datatable data={reports || []} columns={columns} headerColor={"#3B82F6"}/>
            <ReportDialog reportData={{name: "", report_url: ""}} dialogTitle={"Create Report"} onSubmit={onSubmit}
                          open={open} handleClose={handleClose} buttonType={"Create"} />
        </div>
    );
};

export default LookerReports;
