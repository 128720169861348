import {
    Box,
    Button, CircularProgress,
    CircularProgressLabel, css, FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, Icon
} from "@chakra-ui/react";
import {Form, FormikProvider, useFormik} from "formik";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../components/PopupModal/SuccessModal";
import WithAppContext from "../../../../../helpers/appContext";
import instance from "../../../../../helpers/axios";
import {useUpdateAdUploadStatus} from "../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {dv360AdUploadalueLengths, dv360AdUploadInitialValues} from "../../../constant/InitialValues";
import {CreativeType, hostingSource} from "../../../constant/SelectValues";
import DV360AdFileUpload from "./DV360AdFileUpload";
import {AD_UPLOAD_TYPE, DV360_IMAGE_SIZE} from "../../../../../constant";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../helpers/fileValidation";
import {useUploadImage} from "../../../../../hooks/campaign-briefs/useUploadImage";
import _ from "lodash";

const DV360AdDetails = ({
                            data,
                            handlePreviewData,
                            url,
                            method,
                            clientId,
                            campaignId,
                            adUploadId,
                            tabIndex,
                            setTabIndex,
                            context
                        }) => {
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const [approval, setApproval] = useState(false);

    const {mutate} = useUpdateAdUploadStatus();
    const {mutateAsync} = useUploadImage();
    const {setAppLoading} = context;
    const schema = yup.object().shape({
        adName: yup.string().trim().min(3).max(512).required().label('Ad name'),
        description: yup.string().trim().min(3).max(128).required().label('Description'),
        displayName: yup.string().trim().min(3).max(1024).required().label('Display name'),
        creativeType: yup.string().required().label('Creative type'),
        hostingSource: yup.string().required().label('Hosting source'),
        imageURL: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1).max(12).required(),
    });

    const onSubmitImages = async (multiFiles) => {
        let payload = [];
        let otherFile = [];
        let files = [];
        multiFiles.forEach((file) => {
            if (file?.fieldname) {
                otherFile = [...data?.detail?.fileInfoList]
            } else {
                payload.push(file);
            }
        })

        if(payload && payload.length){
            await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: payload,
                    type: AD_UPLOAD_TYPE.DV360
                },
                {
                    onSuccess: (data, variables, context) => {
                        payload = data.fileInfoList;
                        setAppLoading(false)
                    },
                    onError: () => {
                        setAppLoading(false)
                    }
                }
            );
            files = [...otherFile, ...payload]
        }else{
            files = [...otherFile]
        }
        return files;
    }

    const formik = useFormik({
        initialValues: dv360AdUploadInitialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setAppLoading(true)
            const files = await onSubmitImages(values.imageURL);
            const mediaIds = files.map((el) => el.imageHash);
            const dimensions = files.map(() => {
                return {
                    width: "",
                    height: "",
                };
            });
            const fileInfoList = files.map((el) => {
                return {
                    ...el,
                    metadata: [{height: "", width: ""}],
                };
            });
            let payload = {
                name: values.adName,
                description: values.description,
                detail: {
                    name: values.adName,
                    creativeType: values.creativeType,
                    description: values.description,
                    displayName: values.displayName,
                    hostingSource: values.hostingSource,
                    mediaIds: mediaIds,
                    dimensions: dimensions,
                    fileInfoList
                },
            };
            if (!data?.id) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.DV360,
                };
            }
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                        setAppLoading(false)
                    }
                })
                .catch((error) => {
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false)
                });
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (data && data.id) {
            setFieldValue('adName', data.name)
            setFieldValue('description', data.detail.description)
            setFieldValue('displayName', data.detail.displayName)
            setFieldValue('creativeType', data.detail.creativeType)
            setFieldValue('hostingSource', data.detail.hostingSource)
            const imageFile = data?.detail?.fileInfoList.map((el) => el.files);
            setFieldValue('imageURL', imageFile)
        }
    }, [data]);

    useEffect(() => {
        if (tabIndex === 1) {
            if (values && values.imageURL) {
                const files = values.imageURL.map((file) => {
                    if (file && file.fieldname) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`,
                            filename: file.originalname
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
                handlePreviewData(files);
            }
        }
    }, [tabIndex]);

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        navigate(`/campaign-briefs/${campaignId}`, {
            state: {isShowUpload: true}
        })
    }

    const handleStatus = (status) => {
        mutate(
            {
                clientId,
                campaignId,
                adUploadId: data?.id,
                status,
            },
            {
                onSuccess: () => {
                    navigate(`/campaign-briefs/${campaignId}`, {
                        state: {isShowUpload: true},
                    });
                },
            }
        )
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" my={4} mb={7}>
                <Heading color={"gray"} fontSize="xl">
                    Current status:
                    <span style={{marginLeft: "10px"}}>
                        <Icon
                            viewBox="0 0 200 200"
                            mr={2}
                            color={data?.status === "Created" ? "#59AB9E" : data?.status === "Approved" ? "#3F7EE6" : data?.status === "Rejected" ? "#FFA383" : "#FFA383"}
                        >
                            <path
                                fill="currentColor"
                                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                            />
                        </Icon>
                        {data?.status || "Draft"}
                    </span>
                </Heading>
                {data && _.toUpper(data.status) === "CREATED" &&
                    <Box display="flex">
                        <Button
                            size="sm"
                            mr={2}
                            colorScheme="green"
                            backgroundColor="green.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Approved')}
                        >
                            Approve
                        </Button>
                        <Button
                            size="sm"
                            colorScheme="red"
                            backgroundColor="red.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Rejected')}
                        >
                            Reject
                        </Button>
                    </Box>
                }
            </Box>
            <Grid className="fb-upload-detail-form">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid
                            templateColumns="repeat(6, 1fr)"
                            gap={4}
                            className="fb-upload"
                        >
                            <GridItem
                                w="full"
                                colSpan={{base: 6, lg: 4}}
                            >
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="adName"
                                            color="gray"
                                            fontSize="sm"
                                        >
                                            Ad Name - max 512 characters
                                        </FormLabel>
                                        <div className="input-box">
                                            <InputControl
                                                id="adName"
                                                name="adName"
                                                value={values.adName}
                                                placeholder=""
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('adName', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={dv360AdUploadalueLengths.adName}
                                                value={values.adName.length}
                                                color={values.adName.length > dv360AdUploadalueLengths.adName ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.adName.length > dv360AdUploadalueLengths.adName ? dv360AdUploadalueLengths.adName - values.adName.length : values.adName.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="description"
                                            color="gray"
                                            fontSize="sm"
                                        >
                                            Description - max 128 characters
                                        </FormLabel>
                                        <div className="input-box">
                                            <TextareaControl
                                                id="description"
                                                name="description"
                                                value={
                                                    values.description
                                                }
                                                placeholder=""
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('description', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={dv360AdUploadalueLengths.description}
                                                value={values.description.length}
                                                color={
                                                    values.description.length > dv360AdUploadalueLengths.description ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.description.length > dv360AdUploadalueLengths.description ? dv360AdUploadalueLengths.description - values.description.length : values.description.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="displayName"
                                            color="gray"
                                            fontSize="sm"
                                        >
                                            Display Name - max 1024
                                            characters
                                        </FormLabel>
                                        <div className="input-box">
                                            <InputControl
                                                id="displayName"
                                                name="displayName"
                                                value={
                                                    values.displayName
                                                }
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('displayName', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={dv360AdUploadalueLengths.displayName}
                                                value={values.displayName.length}
                                                color={values.displayName.length > dv360AdUploadalueLengths.displayName ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.displayName.length > dv360AdUploadalueLengths.displayName ? dv360AdUploadalueLengths.displayName - values.displayName.length : values.displayName.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="creativeType"
                                            color="gray"
                                            fontSize="sm"
                                        >
                                            Creative Type
                                        </FormLabel>
                                        <div className="input-box">
                                            <SelectControl
                                                id="creativeType"
                                                name="creativeType"
                                                selectProps={{
                                                    placeholder:
                                                        "-- Select One --",
                                                    variant: "outline",
                                                    border: "2px",
                                                    borderRadius: 0,
                                                    borderColor: "gray",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    lineHeight: "16px",
                                                    color: "#757998",
                                                    marginRight:
                                                        "100px",
                                                }}
                                                onChange={(e) => setFieldValue('creativeType', e.target.value)}
                                            >
                                                {CreativeType.map((el) => (
                                                    <option value={el.key} key={el.key}> {el.value} </option>))}
                                            </SelectControl>
                                            <CircularProgress
                                                opacity={0}
                                            >
                                                <CircularProgressLabel>
                                                    0
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="hostingSource"
                                            color="gray"
                                            fontSize="sm"
                                        >
                                            Hosting Source
                                        </FormLabel>
                                        <div className="input-box">
                                            <SelectControl
                                                id="hostingSource"
                                                name="hostingSource"
                                                selectProps={{
                                                    placeholder:
                                                        "-- Select One --",
                                                    variant: "outline",
                                                    border: "2px",
                                                    borderRadius: 0,
                                                    borderColor: "gray",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    lineHeight: "16px",
                                                    color: "#757998",
                                                    marginRight:
                                                        "100px",
                                                }}
                                                onChange={(e) => setFieldValue('hostingSource', e.target.value)}
                                            >
                                                {hostingSource.map(
                                                    (el) => (<option value={el.key} key={el.key}> {el.name} </option>))}
                                            </SelectControl>
                                            <CircularProgress
                                                opacity={0}
                                            >
                                                <CircularProgressLabel>
                                                    0
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem
                                    css={css({
                                        float: "right",
                                        marginRight: "68px",
                                    })}
                                >
                                    <Button
                                        size="small"
                                        css={css({
                                            background:
                                                "#24a0ed !important",
                                            borderRadius: "32px",
                                            width: "134px",
                                            height: "33px",
                                            marginRight: "10px",
                                        })}
                                        disabled={!values.imageURL?.length}
                                        onClick={() => setTabIndex(1)}
                                    >
                                        Preview
                                    </Button>
                                    <Button
                                        size="small"
                                        css={css({
                                            background:
                                                "#4CAF50 !important",
                                            borderRadius: "32px",
                                            width: "134px",
                                            height: "33px",
                                        })}
                                        disabled={!isValid}
                                        type="submit"
                                    >
                                        {data?.id
                                            ? "Update Ad"
                                            : "Submit Ad"}
                                    </Button>
                                </GridItem>
                            </GridItem>
                            <GridItem
                                w="full"
                                colSpan={{base: 6, lg: 2}}
                            >
                                <Box className="file-upload-box">
                                    <DV360AdFileUpload
                                        getHashArray={(value) => {
                                            setFieldValue('imageURL', value)
                                        }}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        type="dv360"
                                        values={values}
                                    />
                                </Box>
                            </GridItem>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Grid>

            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
};

export default WithAppContext(DV360AdDetails);
