import {
    Heading,
    Button,
    Divider,
    Text,
    Input,
    InputLeftElement,
    InputGroup,
    Stack,
    Icon,
    TagLeftIcon,
    Tag,
    HStack, useToast,
} from "@chakra-ui/react";
import Datatable from "../../components/Datatable";
import React, {useEffect, useMemo, useState} from "react";
import {useGetCampaignList} from "../../hooks/campaign-briefs/useGetCampaignList";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {IoMdAddCircle} from "react-icons/io";
import {CheckCircleIcon, CheckIcon, SearchIcon, SmallCloseIcon, WarningIcon} from "@chakra-ui/icons";
import {TEXT_COLOR} from "../../layout/constant/MenuList";
import {useCreateClone} from "../../hooks/campaign-briefs/useCreateClone";
import {FiCopy, FiEye} from "react-icons/fi";
import {TagLabel} from "@chakra-ui/tag";
import {AD_UPLOAD_STATUS} from "../../constant";
import {deleteAllDV360AudioField} from "./components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Audio/store";
import {deleteTheField} from "../../store/adUploads";
import DeleteModel from "../../components/PopupModal/DeleteModel";
import SuccessModal from "../../components/PopupModal/SuccessModal";
import {useDeleteCampaign} from "../../hooks/campaign-briefs/useDeleteCampaign";
import InputBox from "../../components/InputBox";
import {useRecoilState, useRecoilValue} from "recoil";
import {profile} from "../../atoms/authAtom";

const CampaignBriefs = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState();
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const [campaignData, setCampaignData] = useState([]);
    const clientId = useSelector((state) => state.client.clientId);

    const {data, refetch} = useGetCampaignList(clientId);
    const {mutate, isLoading} = useCreateClone();

    useEffect(() => {
        if(roles?.length === 1){
            setIsClient(roles.includes("CampaignManager"));
        }else{
            setIsClient(false)
        }
    }, [roles]);

    useEffect(() => {
        refetch();
        setTimeout(() => {
            refetch()
        }, 2000)
    }, []);

    useEffect(() => {
        setCampaignData(data?.campaigns);
    }, [data]);

    useEffect(() => {
        if (search?.trim()) {
            const searchedCampaign = campaignData.filter((el) => {
                if (el?.name?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (
                    el?.end_date?.toLowerCase().includes(search.trim())
                ) {
                    return true;
                } else if (
                    el?.start_date?.toLowerCase().includes(search.trim())
                ) {
                    return true;
                }
            });
            setCampaignData(searchedCampaign);
        } else {
            setCampaignData(data?.campaigns);
        }
    }, [search]);

    const checkStatusByTag = (value) => {
        return AD_UPLOAD_STATUS[value.toUpperCase()];
    };

    const [isDeleteModalOpen, setDeleteModal] = useState(false);
    const [briefName, setBriefName] = useState(null)
    const [campaignId, setCampaignId] = useState(null);

    const {data : deleteData, refetch: refetchDelete } = useDeleteCampaign(clientId, campaignId);
    let toast = useToast();

    const onCloseDeleteModal = () => {
        refetchDelete().then((resp) => {
            if(resp?.data?.status === 200) {
                refetch();
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    description: "Deleted Successfully!"
                });
                setDeleteModal(false)
            } else {
                setDeleteModal(false)
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: resp?.data?.data?.message || "Something goes wrong!"
                });
            }
        }).catch((err) => {
            setDeleteModal(false)
        })
    }

    function handleDeleteModel(briefName, campId) {
        setDeleteModal(true)
        setBriefName(briefName)
        setCampaignId(campId)
    }

    function onDestroyModel() {
        setDeleteModal(false)
    }


    const columns = useMemo(
        () => [
            {
                Header: "Campaign Name",
                accessor: "name",
            },
            {
                Header: "Business Name",
                accessor: "detail.businessDetails.name",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (data) => {
                    return (
                               <>
                                   {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() &&
                                   <Tag size="lg" variant='subtle' color={"white"} backgroundColor={"#FFC108"} w={"100%"} borderRadius={100}>
                                       <TagLeftIcon boxSize='16px' as={WarningIcon}/>
                                       <TagLabel>Action Needed</TagLabel>
                                   </Tag>
                                   }
                                   {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() &&
                                   <Tag size="lg" variant='subtle' color="white" backgroundColor={"#63C68B"} w={"100%"} borderRadius={100}>
                                       <TagLeftIcon boxSize='16px' as={CheckCircleIcon} />
                                       <TagLabel>Approved</TagLabel>
                                   </Tag>
                                   }
                                   {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() &&
                                   <Tag size="lg" variant='subtle' backgroundColor="#A7A9BD" color="white" w={"100%"} borderRadius={100}>
                                       <TagLeftIcon boxSize='16px' as={SmallCloseIcon} backgroundColor={"white"} color={"#A7A9BD"} borderRadius={100}/>
                                       <TagLabel>Declined</TagLabel>
                                   </Tag>
                                   }
                               </>
                        );
                },
            },
            {
                Header: "Start Date",
                accessor: "start_date",
            },
            {
                Header: "End Date",
                accessor: "end_date",
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
                Cell: (data) => {
                    const status = data.row.values.status;
                    const name = data.row.values.name;
                    const campId = data.row.original.id;
                    return (
                        <HStack>
                            <Button
                                as={Link}
                                size="sm"
                                variant="outline"
                                aria-label="View details"
                                icon={<FiEye/>}
                                to={`/campaign-briefs/${data.row.original.id}`}
                                color="#3B82F6"
                                py={5}
                                w={100}

                            >
                                {AD_UPLOAD_STATUS.CREATED === status.toUpperCase() ? 'View/Edit' : 'View'}
                            </Button>
                            { !roles.includes("Client") &&
                                <>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    aria-label="View details"
                                    icon={<FiEye/>}
                                    color="#FF8189"
                                    py={5}
                                    w={100}
                                    // disabled={"true"}
                                    onClick={() => handleDeleteModel(name, campId)}

                                >
                                    Delete
                                </Button>
                                <Button
                                size="sm"
                                variant="outline"
                                isLoading={isLoading}
                                onClick={() => {
                                mutate({
                                clientId: data.row.original.client_id,
                                campaignBriefId: data.row.original.id,
                            });
                            }}
                                colorScheme="yellow"
                                py={5}
                                >
                                Clone
                                </Button>
                                </>
                            }

                        </HStack>
                    )
                },
            },
        ],
        []
    );

    return (
        <div className="ad-upload-list">
            <DeleteModel heading={"Delete Brief"} name={briefName} isOpen={isDeleteModalOpen}
                          onClose={() => onCloseDeleteModal()} onDestory={ () => onDestroyModel() } />
            <Heading
                color="#757998"
                fontWeight="600"
                fontFamily="Lora"
                size="lg"
                my={5}
                mb={7}
            >
                Campaigns
            </Heading>
            <div className="search">
                <Stack spacing={4}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300"/>}
                        />
                        <Input
                            name="search"
                            type="tel"
                            placeholder="Search"
                            borderRadius={100}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                </Stack>
                {!isClient && <Button
                    backgroundColor="#3B82F6"
                    px="10"
                    marginTop={5}
                    rightIcon={<IoMdAddCircle/>}
                    onClick={() => navigate("/campaign-briefs/new")}
                >
                    Create new brief
                </Button>}
            </div>

            <Divider/>
            <Datatable data={campaignData || []} columns={columns} headerColor={"#3B82F6"}/>
        </div>
    );
};

export default CampaignBriefs;
