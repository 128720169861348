import React, {useState, useEffect} from "react";
import {
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    useToast,
    VStack,
    FormControl,
    FormLabel, extendTheme, ChakraProvider, Input, FormErrorMessage, Checkbox
} from "@chakra-ui/react";
import axios from "axios";
import {Form, Formik, Field} from "formik";
import CheckboxControl, {InputControl, SubmitButton} from "formik-chakra-ui";
import {useRecoilState} from "recoil";
import {profile} from "../../atoms/authAtom";
import Loading from "../../components/Loading";
import "../Login/floatingStyle.css"
import {Link, useNavigate,useParams} from "react-router-dom"
import * as Yup from "yup";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)"
};

export const theme = extendTheme({
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles
                            }
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                            ...activeLabelStyles
                        },
                        label: {
                            top: '10px',
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 2,
                            transformOrigin: "left top"
                        }
                    }
                }
            }
        }
    }
});

const validationSchema = Yup.object({
    password: Yup.string().min(8).max(20).matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ).required(),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match")
        .required()
});

const resetPasswordInitialValue = {
    password: "",
    confirmPassword: "",
};



const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const [visiblePassword,setVisiblePassword] = useState("password")
    const [visibleConfirmPassword,setVisibleConfirmPassword] = useState("password")
    const params = useParams();

    const handleSubmit = async (values, {setSubmitting}) => {
        return await axios({
            method: "POST",
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/change-password/${params.token}`,
            data: { password: values.password },
            withCredentials: false,
        })
            .then((response) => {
                if (+response.status === 200) {
                    toast({
                        isClosable: true,
                        status: "success",
                        variant: "top-accent",
                        position: "top-right",
                        title: "Success",
                        description: "Password Reset Successfully",
                    });
                    window.location = "/login";
                }

            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description:
                        error.response?.data?.message ||
                        "Something went wrong!",
                });
            });
    };

    return isLoading ? (
        <Loading />
    ) :  (
        <Flex align={"center"} bg="gray.50" justify={"center"} minH={"100vh"}>
            <Stack
                spacing={4}
                w={"full"}
                overflow="hidden"
                maxW="container.lg"
                bg={"white"}
                rounded={"xl"}
                boxShadow={"lg"}
                my={12}
            >
                <HStack justifyContent="space-between" alignItems="strech">
                    <Flex p={12} flex={1} align={"center"} justify={"center"}>
                        <VStack alignItems="start">
                            <Image
                                src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
                                height="80px"
                            />
                            <Text color="#79A9FF" style={{marginTop: "-16px"}} fontWeight="600">Powered by War
                                Room.</Text>
                            <Stack spacing={4} py={12} w={"full"} maxW={"md"}>
                                <Heading fontWeight="700" fontFamily="Lora" fontSize={35} color={"#757998"}>
                                    Reset Password.
                                </Heading>
                                <Text color="#A7A9BD" fontWeight="600" style={{marginTop: "0px",marginBottom:"20px"}} fontFamily={"poppins"} fontSize={14}>
                                    Please enter your new password for the Kedet account.
                                </Text>
                                <ChakraProvider theme={theme}>
                                    <Formik
                                        initialValues={resetPasswordInitialValue}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {
                                            ({handleSubmit, dirty, errors, touched, isSubmitting}) => {
                                                return (
                                                    <VStack
                                                        as={Form}
                                                        w="full"
                                                        alignItems="start"
                                                    >
                                                        <Stack
                                                            w="full"
                                                            spacing={3}
                                                            autoComplete="off"
                                                        >
                                                            <FormControl variant="floating" isRequired isInvalid>
                                                                <Box className="login-controller">
                                                                    <Field as={Input} type={visiblePassword} name="password" placeholder=" "
                                                                           className="login-input"/>
                                                                    <FormLabel htmlFor="password" className="login-label">Password</FormLabel>
                                                                </Box>
                                                                {visiblePassword === 'password' &&
                                                                <ViewIcon onClick={() => setVisiblePassword('text')}
                                                                          zIndex="999"
                                                                          cursor="pointer"
                                                                          position={"absolute"} top="30%" right={"26px"} boxSize={6}/>
                                                                }
                                                                {visiblePassword === 'text' &&
                                                                <ViewOffIcon onClick={() => setVisiblePassword('password')}
                                                                             zIndex="999"
                                                                             cursor="pointer"
                                                                             position={"absolute"} top="30%" right={"26px"} boxSize={6}/>
                                                                }
                                                                {
                                                                    touched.password &&
                                                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                                                }
                                                            </FormControl>
                                                            <FormControl variant="floating" isRequired isInvalid>
                                                                <Box className="login-controller">
                                                                    <Field as={Input} type={visibleConfirmPassword} name="confirmPassword" placeholder=" "
                                                                           className="login-input"/>
                                                                    <FormLabel htmlFor="confirmPassword" className="login-label">Confirm Password</FormLabel>
                                                                </Box>
                                                                {visibleConfirmPassword === 'password' &&
                                                                <ViewIcon onClick={() => setVisibleConfirmPassword('text')}
                                                                          zIndex="999"
                                                                          cursor="pointer"
                                                                          position={"absolute"} top="30%" right={"26px"} boxSize={6}/>
                                                                }
                                                                {visibleConfirmPassword === 'text' &&
                                                                <ViewOffIcon onClick={() => setVisibleConfirmPassword('password')}
                                                                             zIndex="999"
                                                                             cursor="pointer"
                                                                             position={"absolute"} top="30%" right={"26px"} boxSize={6}/>
                                                                }
                                                                {
                                                                    touched.confirmPassword &&
                                                                    <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                                                                }
                                                            </FormControl>
                                                        </Stack>
                                                        <SubmitButton
                                                            variant="solid"
                                                            colorScheme="blue"
                                                            backgroundColor={"#79A9FF"}
                                                            borderRadius={0}
                                                            style={{marginTop: "40px", width: "100%"}}
                                                            px="14"
                                                        >
                                                            Submit
                                                        </SubmitButton>
                                                    </VStack>
                                                )
                                            }
                                        }
                                    </Formik>

                                </ChakraProvider>
                            </Stack>
                        </VStack>
                    </Flex>
                    <Flex flex={1}>
                        <Box overflow="hidden">
                            <Image
                                alt={"Login Image"}
                                objectFit="cover"
                                height="100%"
                                src={`${process.env.PUBLIC_URL}/images/login-bg.webp`}
                            />
                        </Box>
                    </Flex>
                </HStack>
            </Stack>
        </Flex>
    );
};

export default ResetPassword;
